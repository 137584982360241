import { TLetter, TTopic, TWord } from './types';

export const REMOVE_CARD: 'REMOVE_CARD' = 'REMOVE_CARD';
export const ADD_CARD: 'ADD_CARD' = 'ADD_CARD';
export const EDIT_CARD: 'EDIT_CARD' = 'EDIT_CARD';
export const SET_LOCATION: 'SET_LOCATION' = 'SET_LOCATION';
export const ON_CLICK: 'ON_CLICK' = 'ON_CLICK';
export const REMOVE_ON_CLICK: 'REMOVE_ON_CLICK' = 'REMOVE_ON_CLICK';
export const REMOVE_LOCATIONS: 'REMOVE_LOCATIONS' = 'REMOVE_LOCATIONS';
export const SET_DECK: 'SET_DECK' = 'SET_DECK';
export const CLEAR_CURRENT_DECK: 'CLEAR_CURRENT_DECK' = 'CLEAR_CURRENT_DECK';
export const SET_WORD: 'SET_WORD' = 'SET_WORD';
export const CLEAR_CURRENT_WORD: 'CLEAR_CURRENT_WORD' = 'CLEAR_CURRENT_WORD';

export const topics: Array<TTopic> = [
  {
    ruTopic: 'Адрес',
    enTopic: 'Address',
  },
  {
    ruTopic: 'Вопросы',
    enTopic: 'Questions',
  },
  {
    ruTopic: 'Страны',
    enTopic: 'Countries',
  },
  {
    ruTopic: 'Языки',
    enTopic: 'Languages',
  },
  {
    ruTopic: 'Города',
    enTopic: 'Cities',
  },
  {
    ruTopic: 'Имена',
    enTopic: 'Names',
  },
  {
    ruTopic: 'Дни недели',
    enTopic: 'Days of Week',
  },
  {
    ruTopic: 'Учеба',
    enTopic: 'Study',
  },
  {
    ruTopic: 'Животные',
    enTopic: 'Animals',
  },
  {
    ruTopic: 'Приветствия',
    enTopic: 'Greetings',
  },
  {
    ruTopic: 'Организации/Места',
    enTopic: 'Organizations',
  },
  {
    ruTopic: 'Профессии',
    enTopic: 'Professions',
  },
  {
    ruTopic: 'Государственность',
    enTopic: 'Statehood',
  },
  {
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    ruTopic: 'Разное',
    enTopic: 'Other',
  },
  {
    ruTopic: 'Числа',
    enTopic: 'Numbers',
  },
  {
    ruTopic: 'Прилагательные',
    enTopic: 'Adjectives',
  },
  {
    ruTopic: 'Местоимения',
    enTopic: 'Pronouns',
  },
  {
    ruTopic: 'Предлоги',
    enTopic: 'Prepositions',
  },
  {
    ruTopic: 'Глаголы',
    enTopic: 'Verbs',
  },
  {
    ruTopic: 'Все',
    enTopic: 'All',
  },
];

export const partsOfSpeach: Array<TTopic> = [
  {
    ruTopic: 'Прилагательные',
    enTopic: 'Adjectives',
  },
  {
    ruTopic: 'Глаголы',
    enTopic: 'Verbs',
  },
  {
    ruTopic: 'Местоимения',
    enTopic: 'Pronouns',
  },
  {
    ruTopic: 'Предлоги',
    enTopic: 'Prepositions',
  },
  {
    ruTopic: 'Числа',
    enTopic: 'Numbers',
  },
];

export const topicsForRender: Array<TTopic> = [
  {
    ruTopic: 'Приветствия',
    enTopic: 'Greetings',
  },
  {
    ruTopic: 'Вопросы',
    enTopic: 'Questions',
  },
  {
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    ruTopic: 'Профессии',
    enTopic: 'Professions',
  },
  {
    ruTopic: 'Цвета',
    enTopic: 'Colors',
  },
  {
    ruTopic: 'Организации/Места',
    enTopic: 'Organizations',
  },
  {
    ruTopic: 'Государственность',
    enTopic: 'Statehood',
  },
  {
    ruTopic: 'Дни недели',
    enTopic: 'Days of Week',
  },
  {
    ruTopic: 'Учеба',
    enTopic: 'Study',
  },
  {
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    ruTopic: 'Адрес',
    enTopic: 'Address',
  },
  {
    ruTopic: 'Страны',
    enTopic: 'Countries',
  },
  {
    ruTopic: 'Языки',
    enTopic: 'Languages',
  },
  {
    ruTopic: 'Города',
    enTopic: 'Cities',
  },
  {
    ruTopic: 'Имена',
    enTopic: 'Names',
  },
  {
    ruTopic: 'Животные',
    enTopic: 'Animals',
  },
  {
    ruTopic: 'Разное',
    enTopic: 'Other',
  },
];

export const vocabulary: Array<TWord> = [
  {
    word: 'מה',
    vocalization: 'מה',
    translation: 'Что?',
    transcription: 'ма',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'מי',
    vocalization: 'מי',
    translation: 'Кто?',
    transcription: 'ми',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'מאין',
    vocalization: 'מאין',
    translation: 'Откуда?',
    transcription: 'мэАйн',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'איפה',
    vocalization: 'איפה',
    translation: 'Где?',
    transcription: 'эйфо',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'למה',
    vocalization: 'למה',
    translation: 'Почему?',
    transcription: 'лАма',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'מתי',
    vocalization: 'מתי',
    translation: 'Когда?',
    transcription: 'матАй',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'לאן',
    vocalization: 'לאן',
    translation: 'Куда?',
    transcription: 'леАн',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'על מי',
    vocalization: 'על מי',
    translation: 'О ком?',
    transcription: 'Аль мИ',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'על מה',
    vocalization: 'על מע',
    translation: 'О чем?',
    transcription: 'Аль мА',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'עם מי',
    vocalization: 'עם מי',
    translation: 'С кем?',
    transcription: 'Им мИ',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'של מי',
    vocalization: 'של מי',
    translation: 'Чье?',
    transcription: 'шЕль мИ',
    ruTopic: ['Вопросы'],
    enTopic: ['Questions'],
  },
  {
    word: 'שרה',
    vocalization: 'שרה',
    translation: 'Сара',
    transcription: 'Сара',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'יצחק',
    vocalization: 'יצחק',
    translation: 'Ицхак',
    transcription: 'ИцхАк',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'רותי',
    vocalization: 'רותי',
    translation: 'Рути',
    transcription: 'РУти',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'יוסף',
    vocalization: 'יוסף',
    translation: 'Йосеф',
    transcription: 'ЙОсеф',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'מרינה',
    vocalization: 'מרינה',
    translation: 'Марина',
    transcription: 'МарИна',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'דינה',
    vocalization: 'דינה',
    translation: 'Дина',
    transcription: 'ДИна',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'דניאל',
    vocalization: 'דניאל',
    translation: 'Даниэль',
    transcription: 'Даниэль',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'דורית',
    vocalization: 'דורית',
    translation: 'Дорит (ж.р.)',
    transcription: 'Дорит',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'מיכאל',
    vocalization: 'מיכאל',
    translation: 'Михаэль',
    transcription: 'Михаэль',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'סימה',
    vocalization: 'סימה',
    translation: 'Сима',
    transcription: 'Сима',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'אורי',
    vocalization: 'אורי',
    translation: 'Ури',
    transcription: 'Ури',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'רחל',
    vocalization: 'רחל',
    translation: 'Рахэль',
    transcription: 'РахЭль',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'ענת',
    vocalization: 'ענת',
    translation: 'Анат',
    transcription: 'Анат',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'גיון',
    vocalization: 'גיון',
    translation: 'Джон',
    transcription: 'Джон',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'בוריס',
    vocalization: 'בוריס',
    translation: 'Борис',
    transcription: 'Борис',
    ruTopic: ['Имена'],
    enTopic: ['Names'],
  },
  {
    word: 'ירושלים',
    vocalization: 'ירושלים',
    translation: 'Иерусалим',
    transcription: 'ЕрушалАим',
    ruTopic: ['Города'],
    enTopic: ['Cities'],
  },
  {
    word: 'טבריה',
    vocalization: 'טבריה',
    translation: 'Тверия',
    transcription: 'ТвЕрия',
    ruTopic: ['Города'],
    enTopic: ['Cities'],
  },
  {
    word: 'אילת',
    vocalization: 'אילת',
    translation: 'Эйлат',
    transcription: 'Эйлат',
    ruTopic: ['Города'],
    enTopic: ['Cities'],
  },
  {
    word: 'תל-אביב',
    vocalization: 'תל-אביב',
    translation: 'Тель-Авив',
    transcription: 'Тель-АвИв',
    ruTopic: ['Города'],
    enTopic: ['Cities'],
  },
  {
    word: 'חיפה',
    vocalization: 'חיפה',
    translation: 'Хайфа',
    transcription: 'ХэйфА',
    ruTopic: ['Города'],
    enTopic: ['Cities'],
  },
  {
    word: 'פריז',
    vocalization: 'פריז',
    translation: 'Париж',
    transcription: 'Париз',
    ruTopic: ['Города'],
    enTopic: ['Cities'],
  },
  {
    word: 'ספרד',
    vocalization: 'ספרד',
    translation: 'Испания',
    transcription: 'СфарАд',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'אנגליה',
    vocalization: 'אנגליה',
    translation: 'Англия',
    transcription: 'Англиа',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'רוסיה',
    vocalization: 'רוסיה',
    translation: 'Россия',
    transcription: 'РУссиа',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'אוקראינה',
    vocalization: 'אוקראינה',
    translation: 'Украина',
    transcription: 'УкрАйна',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'צרפת',
    vocalization: 'צרפת',
    translation: 'Франция',
    transcription: 'ФрАнкрайх',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'ישראל',
    vocalization: 'ישראל',
    translation: 'Израиль',
    transcription: 'ИсраЭль',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'עיראק',
    vocalization: 'עיראק',
    translation: 'Ирак',
    transcription: 'Ирак',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'איראן',
    vocalization: 'איראן',
    translation: 'Иран',
    transcription: 'Иран',
    ruTopic: ['Страны'],
    enTopic: ['Countries'],
  },
  {
    word: 'עברית',
    vocalization: 'עברית',
    translation: 'Иврит',
    transcription: 'Иврит',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'רוסית',
    vocalization: 'רוּסִית',
    translation: 'Русский',
    transcription: 'русИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'אנגלית',
    vocalization: 'אַנגלִית',
    translation: 'Английский',
    transcription: 'англИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'צרפתית',
    vocalization: 'צָרפָתִית',
    translation: 'Испанский',
    transcription: 'царфатИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'גרמנית',
    vocalization: 'גֶרמָנִית',
    translation: 'Немецкий',
    transcription: 'германИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'ספרדימ',
    vocalization: 'ספָרַדִית',
    translation: 'Французский',
    transcription: 'сфарадит',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'רומנית',
    vocalization: 'רומנית',
    translation: 'Румынский',
    transcription: 'романит',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'ערבית',
    vocalization: 'ערבית',
    translation: 'Арабский',
    transcription: 'арвИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'אמהרית',
    vocalization: 'אמהרית',
    translation: 'Амхарский',
    transcription: 'ам(х)арИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'יפנית',
    vocalization: 'יפנית',
    translation: 'Японский',
    transcription: 'яфанИт',
    ruTopic: ['Языки'],
    enTopic: ['Languages'],
  },
  {
    word: 'בית',
    vocalization: 'בַּיִת',
    translation: 'Дом',
    transcription: 'бАйт',
    ruTopic: ['Адрес'],
    enTopic: ['Address'],
  },
  {
    word: 'רחוב',
    vocalization: 'רְחוֹב',
    translation: 'Улица',
    transcription: 'рэхОв',
    ruTopic: ['Адрес'],
    enTopic: ['Address'],
  },
  {
    word: 'דירה',
    vocalization: 'דִּירָה',
    translation: 'Квартира',
    transcription: 'дирА',
    ruTopic: ['Адрес'],
    enTopic: ['Address'],
  },
  {
    word: 'מספר',
    vocalization: 'מִסְפָּר',
    translation: 'Номер',
    transcription: 'миспАр',
    ruTopic: ['Адрес'],
    enTopic: ['Address'],
  },
  {
    word: 'יום טוב',
    vocalization: 'יוֹם טוֹב',
    translation: 'Хорошего дня',
    transcription: 'йом тов',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'בוקר טוב',
    vocalization: 'בּוֹקֶר טוֹב',
    translation: 'Хорошего утра',
    transcription: 'бОкер тов',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'ערב טוב',
    vocalization: 'עֶרֶב טוֹב',
    translation: 'Хорошего вечера',
    transcription: 'Эрев тов',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'לילה טוב',
    vocalization: 'לַילָה טוֹב',
    translation: 'Хорошей ночи',
    transcription: 'лАйла тов',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'סליחה',
    vocalization: 'סליחה',
    translation: 'Извините',
    transcription: 'слихА',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'תודה רבה',
    vocalization: 'תודה רבה',
    translation: 'Большое спасибо',
    transcription: 'тодА рабА',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'בבקשה',
    vocalization: 'בְּבַקָּשָׁה',
    translation: 'Пожалуйста',
    transcription: 'бевакашА',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'בהצלחה',
    vocalization: 'בְּהַצלָחָה',
    translation: 'Успеха',
    transcription: 'бе(h)ацлаха',
    ruTopic: ['Приветствия'],
    enTopic: ['Greetings'],
  },
  {
    word: 'אולפן',
    vocalization: 'אוּלְפָּן',
    translation: 'Ульпан',
    transcription: 'ульпАн',
    ruTopic: ['Учеба', 'Организации/Места'],
    enTopic: ['Study', 'Organizations/Places'],
  },
  {
    word: 'אוניברסיטה',
    vocalization: 'אוּנִיבֶרְסִיטָה',
    translation: 'Университет',
    transcription: 'унивЭрсита',
    ruTopic: ['Учеба', 'Организации/Места'],
    enTopic: ['Study', 'Organizations/Places'],
  },
  {
    word: 'משרד',
    vocalization: 'מִשְׂרָד',
    translation: 'Офис',
    transcription: 'мисрАд',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'בית החולים',
    vocalization: 'בית החולים',
    translation: 'Больница',
    transcription: 'бЕйт холИм',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'בית משפט',
    vocalization: 'בית משפט',
    translation: 'Суд',
    transcription: 'бЕйт мишпАд',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'שוק',
    vocalization: 'שׁוּק',
    translation: 'Рынок',
    transcription: 'шУк',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'תלמיד',
    vocalization: 'תַּלְמִיד',
    translation: 'Ученик',
    transcription: 'талмИд',
    ruTopic: ['Учеба'],
    enTopic: ['Study'],
  },
  {
    word: 'סטודנט',
    vocalization: 'סְטוּדֶנְט',
    translation: 'Студент',
    transcription: 'студЭнт',
    ruTopic: ['Учеба'],
    enTopic: ['Study'],
  },
  {
    word: 'מורה',
    vocalization: 'מוֹרֶה',
    translation: 'Учительница',
    transcription: 'морА',
    ruTopic: ['Учеба', 'Профессии'],
    enTopic: ['Study', 'Professions'],
  },
  {
    word: 'בית-ספר',
    vocalization: 'בית-ספר',
    translation: 'Школа',
    transcription: 'бЕйт сэфЭр',
    ruTopic: ['Учеба', 'Организации/Места'],
    enTopic: ['Study', 'Organizations/Places'],
  },
  {
    word: 'כיתה',
    vocalization: 'כִּיתָּה',
    translation: 'Класс',
    transcription: 'китА',
    ruTopic: ['Учеба'],
    enTopic: ['Study'],
  },
  {
    word: 'משטרה',
    vocalization: 'מִשְׁטָרָה',
    translation: 'Полиция',
    transcription: 'миштарА',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'בנק',
    vocalization: 'בַּנְק',
    translation: 'Банк',
    transcription: 'банк',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'קפה',
    vocalization: 'קפה',
    translation: 'Кафе',
    transcription: 'кафе',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'ספרייה',
    vocalization: 'ספרייה',
    translation: 'Библиотека',
    transcription: 'сифриЯ',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'מסעדה',
    vocalization: 'מסעדה',
    translation: 'Ресторан',
    transcription: 'мисадА',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'מפעל',
    vocalization: 'מפעל',
    translation: 'Завод',
    transcription: 'мифАль',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'חנות',
    vocalization: 'חנות',
    translation: 'Магазин',
    transcription: 'ханУт',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'גן ילדים',
    vocalization: 'גן ילדים',
    translation: 'Детский сад',
    transcription: 'гАн йолдИм',
    ruTopic: ['Организации/Места'],
    enTopic: ['Organizations/Places'],
  },
  {
    word: 'פקיד',
    vocalization: 'פָּקִיד',
    translation: 'Чиновник/служащий',
    transcription: 'пакИд',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'רופא',
    vocalization: 'רופא',
    translation: 'Врач',
    transcription: 'рофЭ',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'מוכרת',
    vocalization: 'מוכרת',
    translation: 'Продавщица',
    transcription: 'мохЭрэт',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'מלצרית',
    vocalization: 'מלצרית',
    translation: 'Официантка',
    transcription: 'мельцарИт',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'שומר',
    vocalization: 'שומר',
    translation: 'Охранник',
    transcription: 'шомЭр',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'סופרת',
    vocalization: 'סופרת',
    translation: 'Писательница',
    transcription: 'софЭрет',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'עיתונאי',
    vocalization: 'עיתונאי',
    translation: 'Журналист',
    transcription: 'итонАи',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'מנהל',
    vocalization: 'מנהל',
    translation: 'Директор',
    transcription: 'мэнаЭл',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'מזכירה',
    vocalization: 'מַזְכִּירָהּ',
    translation: 'Секретарша',
    transcription: 'мазкирА',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'עורך דין',
    vocalization: 'עורך דין',
    translation: 'Адвокат',
    transcription: 'Орэх дИн',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'מהנדס',
    vocalization: 'מְהַנְדֵּס',
    translation: 'Инженер',
    transcription: 'мэандЭс',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'עבודה',
    vocalization: 'עבודה',
    translation: 'Работа',
    transcription: 'аводА',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'וטרינר',
    vocalization: 'וטרינר',
    translation: 'Ветеринар',
    transcription: 'ветеринАр',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'אחות',
    vocalization: 'אחות',
    translation: 'Медсестра',
    transcription: 'ахОт',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'גננת',
    vocalization: 'גננת',
    translation: 'Воспитательница',
    transcription: 'ганЭнэт',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'חייל',
    vocalization: 'חייל',
    translation: 'Солдат',
    transcription: 'хаЯл',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'חיילת',
    vocalization: 'חיילת',
    translation: 'Солдатка',
    transcription: 'хайЭлэт',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'תפקיד',
    vocalization: 'תפקיד',
    translation: 'Должность',
    transcription: 'тафкИд',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'מקצוע',
    vocalization: 'מקצוע',
    translation: 'Должность',
    transcription: 'микцОа',
    ruTopic: ['Профессии'],
    enTopic: ['Professions'],
  },
  {
    word: 'משפחה',
    vocalization: 'מִשְׁפָּחָה',
    translation: 'Семья',
    transcription: 'мишпахА',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'הורים',
    vocalization: 'הורים',
    translation: 'Родители',
    transcription: 'hорИм',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'אבא',
    vocalization: 'אבא',
    translation: 'Папа',
    transcription: 'Аба',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'אימא',
    vocalization: 'אִימָּא',
    translation: 'Мама',
    transcription: 'Има',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'בן',
    vocalization: 'בן',
    translation: 'Сын',
    transcription: 'бЕн',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'בת',
    vocalization: 'במ',
    translation: 'Дочь',
    transcription: 'бАт',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'תינוק',
    vocalization: 'תינוק',
    translation: 'Младенец',
    transcription: 'тинОк',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'בעל',
    vocalization: 'בעל',
    translation: 'Муж',
    transcription: 'баАл',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'אישה',
    vocalization: 'אִישָּׁה',
    translation: 'Жена',
    transcription: 'ишА',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'אח',
    vocalization: 'אח',
    translation: 'Брат',
    transcription: 'Ах',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'אחות',
    vocalization: 'אחות',
    translation: 'Сестра',
    transcription: 'ахОт',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'סבתא',
    vocalization: 'סבתא',
    translation: 'Бабушка',
    transcription: 'сАвта',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'סבא',
    vocalization: 'סבא',
    translation: 'Дедушка',
    transcription: 'сАба',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'דוד',
    vocalization: 'דוד',
    translation: 'Дядя',
    transcription: 'дОд',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'דודה',
    vocalization: 'דודה',
    translation: 'Тетя',
    transcription: 'додА',
    ruTopic: 'Семья',
    enTopic: 'Family',
  },
  {
    word: 'לחם',
    vocalization: 'לחם',
    translation: 'Хлеб',
    transcription: 'лэхЭм',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'חלב',
    vocalization: 'חלב',
    translation: 'Молоко',
    transcription: 'халАв',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'דג',
    vocalization: 'דג',
    translation: 'Рыба',
    transcription: 'дАг',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'בשר',
    vocalization: 'בשר',
    translation: 'Мясо',
    transcription: 'басАр',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'ירקות',
    vocalization: 'ירקות',
    translation: 'Овощи',
    transcription: 'йеракОт',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'פירות',
    vocalization: 'פירות',
    translation: 'Фрукты',
    transcription: 'перОт',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'קפה',
    vocalization: 'קפה',
    translation: 'Кофе',
    transcription: 'кафЭ',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'תה',
    vocalization: 'תה',
    translation: 'Чай',
    transcription: 'тЕ',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'דבש',
    vocalization: 'דבש',
    translation: 'Мед',
    transcription: 'двАш',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'מיצ',
    vocalization: 'מיצ',
    translation: 'Сок',
    transcription: 'мИц',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'גלידה',
    vocalization: 'גלידה',
    translation: 'Мороженое',
    transcription: 'глидА',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'עוגה',
    vocalization: 'עוגה',
    translation: 'Пирог',
    transcription: 'угА',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'מים',
    vocalization: 'מים',
    translation: 'Вода',
    transcription: 'майИм',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'סוכר',
    vocalization: 'סוכר',
    translation: 'Сахар',
    transcription: 'сохЕр',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'שווקולד',
    vocalization: 'שוקולד',
    translation: 'Шоколад',
    transcription: 'шОколад',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'לימון',
    vocalization: 'לימון',
    translation: 'Лимон',
    transcription: 'лимОн',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'תות',
    vocalization: 'תות',
    translation: 'Клубника',
    transcription: 'тУт',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'תות שדה',
    vocalization: 'תות שדה',
    translation: 'Полевая клубника',
    transcription: 'тУт садЭ',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'ענבים',
    vocalization: 'ענבים',
    translation: 'Виноград',
    transcription: 'анавИм',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'תפוז',
    vocalization: 'תפוז',
    translation: 'Апельсин',
    transcription: 'тапУз',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'אגס',
    vocalization: 'אגס',
    translation: 'Груша',
    transcription: 'агАс',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'תפוח',
    vocalization: 'תַּפּוּחַ',
    translation: 'Яблоко',
    transcription: 'тапУах',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'ירוק',
    vocalization: 'ירוק',
    translation: 'Зелень',
    transcription: 'ярОк',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'בצל',
    vocalization: 'בצל',
    translation: 'Лук',
    transcription: 'бацАл',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'חציל',
    vocalization: 'חציל',
    translation: 'Баклажан',
    transcription: 'хацИл',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'מלפפון',
    vocalization: 'מלפפון',
    translation: 'Огурец',
    transcription: 'мелафефОн',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'עגבנייה',
    vocalization: 'עגבנייה',
    translation: 'Помидор',
    transcription: 'агваниЯ',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'חסה',
    vocalization: 'חסה',
    translation: 'Салат',
    transcription: 'хасА',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'כרובית',
    vocalization: 'כרובית',
    translation: 'Цветная капуста',
    transcription: 'крувИт',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'צנונית',
    vocalization: 'צנונית',
    translation: 'Редиска',
    transcription: 'цнонИт',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'סלט',
    vocalization: 'סלט',
    translation: 'Салат',
    transcription: 'салАт',
    ruTopic: 'Еда',
    enTopic: 'Food',
  },
  {
    word: 'אפס',
    vocalization: 'אֶפֶס',
    translation: 'Ноль',
    transcription: 'Эфес',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'אחת',
    vocalization: 'אַחַת',
    translation: 'Один',
    transcription: 'ахАт',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'שמיים',
    vocalization: 'שְׁתַּיִים',
    translation: 'Два',
    transcription: 'штАйм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'שלוש',
    vocalization: 'שָׁלוֹשׁ',
    translation: 'Три',
    transcription: 'шалОш',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'ארבע',
    vocalization: 'אַרְבַּע',
    translation: 'Четыре',
    transcription: 'арбА',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'חמש',
    vocalization: 'חָמֵשׁ',
    translation: 'Пять',
    transcription: 'хамЭш',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'שש',
    vocalization: 'שֵׁשׁ',
    translation: 'Шесть',
    transcription: 'шеш',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'שבע',
    vocalization: 'שֶׁבַע',
    translation: 'Семь',
    transcription: 'шэвА',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'שמונה',
    vocalization: 'שְׁמוֹנֶה',
    translation: 'Восемь',
    transcription: 'шмони',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'תשע',
    vocalization: 'תֵּשַׁע',
    translation: 'Девять',
    transcription: 'тэшА',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'עשר',
    vocalization: 'עֶשֶׂר',
    translation: 'Десять',
    transcription: 'Эсрэ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
  },
  {
    word: 'אפס',
    vocalization: 'אפס',
    translation: 'Ноль',
    transcription: 'Эфэс',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 0,
  },
  {
    word: 'אחת',
    vocalization: 'אחת',
    translation: 'Один',
    transcription: 'ахАт',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 1,
  },
  {
    word: 'שמיים',
    vocalization: 'שתיים',
    translation: 'Два',
    transcription: 'штАйм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 2,
  },
  {
    word: 'שלוש',
    vocalization: 'שלוש',
    translation: 'Три',
    transcription: 'шалОш',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 3,
  },
  {
    word: 'ארבע',
    vocalization: 'ארבע',
    translation: 'Четыре',
    transcription: 'арбА',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 4,
  },
  {
    word: 'חמש',
    vocalization: 'חמש',
    translation: 'Пять',
    transcription: 'хамЭш',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 5,
  },
  {
    word: 'שש',
    vocalization: 'שש',
    translation: 'Шесть',
    transcription: 'шеш',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 6,
  },
  {
    word: 'שבע',
    vocalization: 'שבע',
    translation: 'Семь',
    transcription: 'шэвА',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 7,
  },
  {
    word: 'שמונה',
    vocalization: 'שמונה',
    translation: 'Восемь',
    transcription: 'шмони',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 8,
  },
  {
    word: 'תשע',
    vocalization: 'תשע',
    translation: 'Девять',
    transcription: 'тэшА',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 9,
  },
  {
    word: 'עשר',
    vocalization: 'עשר',
    translation: 'Десять',
    transcription: 'эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 10,
  },
  {
    word: 'אחת עשרה',
    vocalization: 'אַחַת עֶשׂרֵה',
    translation: 'Одиннадцать',
    transcription: 'ахАт эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 11,
  },
  {
    word: 'שתיים עשרה',
    vocalization: 'שְׁתֵיים עֶשׂרֵה',
    translation: 'Двенадцать',
    transcription: 'штэйм эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 12,
  },
  {
    word: 'שלוש עשרה',
    vocalization: 'שְׁלוֹשׁ עֶשׂרֵה',
    translation: 'Тринадцать',
    transcription: 'шлош эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 13,
  },
  {
    word: 'ארבע עשרה',
    vocalization: 'אַרְבַּע עֶשׂרֵה',
    translation: 'Четырнадцать',
    transcription: 'арбА эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 14,
  },
  {
    word: 'חמש עשדה',
    vocalization: 'חֲמֵשׁ עֶשׂרֵה',
    translation: 'Пятнадцать',
    transcription: 'хамЭш эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 15,
  },
  {
    word: 'שש עשדה',
    vocalization: 'שֵׁשׁ עֶשׂרֵה',
    translation: 'Шестнадцать',
    transcription: 'шеш эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 16,
  },
  {
    word: 'שבע עשדה',
    vocalization: 'שְׁבַע עֶשׂרֵה',
    translation: 'Семнадцать',
    transcription: 'шва эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 17,
  },
  {
    word: 'שמונה עשדה',
    vocalization: 'שְׁמוֹנֶה עֶשׂרֵה',
    translation: 'Восемнадцать',
    transcription: 'шмонЭ эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 18,
  },
  {
    word: 'תשע עשרה',
    vocalization: 'תְּשַׁע עֶשׂרֵה',
    translation: 'Девятнадцать',
    transcription: 'тша эсрЭ',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 19,
  },
  {
    word: 'עשרים',
    vocalization: 'עֶשְׂרִים',
    translation: 'Двадцать',
    transcription: 'эсрИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 20,
  },
  {
    word: 'שלושים',
    vocalization: 'שׁלוֹשִים',
    translation: 'Тридцать',
    transcription: 'шлошИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 30,
  },
  {
    word: 'ארבעים',
    vocalization: 'אַרְבָּעִים',
    translation: 'Сорок',
    transcription: 'арбаИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 40,
  },
  {
    word: 'חמישים',
    vocalization: 'חֲמִישִׁים',
    translation: 'Пятьдесят',
    transcription: 'хамишИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 50,
  },
  {
    word: 'ששים',
    vocalization: 'שִׁשִׁים',
    translation: 'Шестьдесят',
    transcription: 'шишИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 60,
  },
  {
    word: 'שבעים',
    vocalization: 'שִׁבְעִים',
    translation: 'Семьдесят',
    transcription: 'шивИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 70,
  },
  {
    word: 'שמונים',
    vocalization: 'שְׁמוֹנִים',
    translation: 'Восемьдесят',
    transcription: 'шмонИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 80,
  },
  {
    word: 'משעים',
    vocalization: 'תִּשְׁעים',
    translation: 'Девяносто',
    transcription: 'тишИм',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 90,
  },
  {
    word: 'מאה',
    vocalization: 'מאה',
    translation: 'Сто',
    transcription: 'мЭа',
    ruTopic: ['Числа'],
    enTopic: ['Numbers'],
    num: 100,
  },
  {
    word: 'חתול',
    vocalization: 'חתול',
    translation: 'Кот',
    transcription: 'хатУль',
    ruTopic: ['Животные'],
    enTopic: ['Animals'],
  },
  {
    word: 'חמור',
    vocalization: 'חמור',
    translation: 'Осел',
    transcription: 'хамОр',
    ruTopic: ['Животные'],
    enTopic: ['Animals'],
  },
  {
    word: 'כלב',
    vocalization: 'כלב',
    translation: 'Собака',
    transcription: 'кЭлэв',
    ruTopic: ['Животные'],
    enTopic: ['Animals'],
  },
  {
    word: 'תרנגול',
    vocalization: 'תַּרְנְגוֹל',
    translation: 'Петух',
    transcription: 'тарнегОль',
    ruTopic: ['Животные'],
    enTopic: ['Animals'],
  },
  {
    word: 'תרנגולת',
    vocalization: 'תרנגולת',
    translation: 'Курица',
    transcription: 'тарнегОлет',
    ruTopic: ['Животные'],
    enTopic: ['Animals'],
  },
  {
    word: 'יום ראשון',
    vocalization: 'יום ראשון',
    translation: 'Воскресенье (день первый)',
    transcription: 'йом ришОн',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'יום שני',
    vocalization: 'יום שני',
    translation: 'Понедельник (день второй)',
    transcription: 'йом шэнИ',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'יום שלישי',
    vocalization: 'יום שלישי',
    translation: 'Вторник (день третий)',
    transcription: 'йом шлишИ',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'יום רביעי',
    vocalization: 'יום רביעי',
    translation: 'Среда (день четвертый)',
    transcription: 'йом рэвии',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'יום חמישי',
    vocalization: 'יום חמישי',
    translation: 'Четверг (день пятый)',
    transcription: 'йом хамиши',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'יום שישי',
    vocalization: 'יום שישי',
    translation: 'Пятница (день шестой)',
    transcription: 'йом шишИ',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'שבת',
    vocalization: 'שבת',
    translation: 'Суббота/шаббат',
    transcription: 'шаббАт',
    ruTopic: ['Дни недели'],
    enTopic: ['Days of Week'],
  },
  {
    word: 'אני',
    vocalization: 'אני',
    translation: 'Я',
    transcription: 'анИ',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'את',
    vocalization: 'אמ',
    translation: 'Ты (ж.р.)',
    transcription: 'ат',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'אמה',
    vocalization: 'אתה',
    translation: 'Ты (м.р.)',
    transcription: 'атА',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'הוא',
    vocalization: 'הוא',
    translation: 'Он',
    transcription: '(х)у',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'היא',
    vocalization: 'היא',
    translation: 'Она',
    transcription: '(х)и',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'אנחנו',
    vocalization: 'אנחנו',
    translation: 'Мы',
    transcription: 'анАхну',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'הן',
    vocalization: 'הן',
    translation: 'Они (ж.р)',
    transcription: '(х)Эн',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'הם',
    vocalization: 'הם',
    translation: 'Они (м.р.)',
    transcription: '(х)Эм',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'אתן',
    vocalization: 'אתן',
    translation: 'Вы (ж.р.)',
    transcription: 'атЭн',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'אמם',
    vocalization: 'אמם',
    translation: 'Вы (м.р.)',
    transcription: 'атЭм',
    ruTopic: ['Местоимения'],
    enTopic: ['Pronouns'],
  },
  {
    word: 'טוב',
    vocalization: 'טוב',
    translation: 'Хороший',
    transcription: 'тов',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'זול',
    vocalization: 'זול',
    translation: 'Дешевый',
    transcription: 'зол',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'קטן',
    vocalization: 'קטן',
    translation: 'Маленький',
    transcription: 'катАн',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'יקר',
    vocalization: 'יקר',
    translation: 'Дорогой (деньги)',
    transcription: 'йакАр',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'גדול',
    vocalization: 'גדול',
    translation: 'Большой',
    transcription: 'гадОль',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'יפה',
    vocalization: 'יפה',
    translation: 'Красивый',
    transcription: 'йафЭ',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'חשוב',
    vocalization: 'חשוב',
    translation: 'Важный',
    transcription: 'хашУв',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'מעניין',
    vocalization: 'מעניין',
    translation: 'Интересный',
    transcription: 'мэаньЕн',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'נחמד',
    vocalization: 'נחמד',
    translation: 'Милый',
    transcription: 'нэхмАд',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'קצר',
    vocalization: 'קצר',
    translation: 'Короткий',
    transcription: 'кацАр',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'ארוך',
    vocalization: 'ארוך',
    translation: 'Длинный',
    transcription: 'арОх',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'קל',
    vocalization: 'קל',
    translation: 'Легкий',
    transcription: 'каль',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'מפורסם',
    vocalization: 'מפורסם',
    translation: 'Известный',
    transcription: 'мэфурсАм',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'קשה',
    vocalization: 'קשה',
    translation: 'Трудный',
    transcription: 'кашЭ',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'חדש',
    vocalization: 'חדש',
    translation: 'Новый',
    transcription: 'хадАш',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'נוח',
    vocalization: 'נוֹחַ',
    translation: 'Удобный',
    transcription: 'нОах',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'חולה',
    vocalization: 'חוֹלֶה',
    translation: 'Больной',
    transcription: 'холЭ',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'חכם',
    vocalization: 'חכם',
    translation: 'Умный',
    transcription: 'хахАм',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'צודק',
    vocalization: 'צודק',
    translation: 'Правый (прав)',
    transcription: 'цодЭк',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'נכון',
    vocalization: 'נכון',
    translation: 'Правильный',
    transcription: 'нахОн',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'מצוין',
    vocalization: 'מצוין',
    translation: 'Отличный',
    transcription: 'мэцуЯн',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'שכורה',
    vocalization: 'שכורה',
    translation: 'Съемная',
    transcription: 'схурА',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'עשיר',
    vocalization: 'עשיר',
    translation: 'Богатый',
    transcription: 'ашИр',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'גרוש',
    vocalization: 'גרוש',
    translation: 'Разведенный',
    transcription: 'гарУш',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'רווק',
    vocalization: 'רווק',
    translation: 'Холостой',
    transcription: 'равАк',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'נשוי',
    vocalization: 'נשוי',
    translation: 'В браке (прил.)',
    transcription: 'насУй',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'קר',
    vocalization: 'קר',
    translation: 'Холодный',
    transcription: 'кАр',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'חם',
    vocalization: 'חם',
    translation: 'Горячий',
    transcription: 'хАм',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'ישן',
    vocalization: 'יָשָׁן',
    translation: 'Старый (не новый)',
    transcription: 'яшАн',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'זקן',
    vocalization: 'זקן',
    translation: 'Старый',
    transcription: 'закЭн',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'צעיר',
    vocalization: 'צעיר',
    translation: 'Молодой',
    transcription: 'цаИр',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'ותיק',
    vocalization: 'ותיק',
    translation: 'Со стажем',
    transcription: 'ватИк',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'דתי',
    vocalization: 'דתי',
    translation: 'Религиозный',
    transcription: 'датИ',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'טרי',
    vocalization: 'טרי',
    translation: 'Свежий',
    transcription: 'тарИ',
    ruTopic: ['Прилагательные'],
    enTopic: ['Adjectives'],
  },
  {
    word: 'לבן',
    vocalization: 'לבן',
    translation: 'Белый',
    transcription: 'лавАн',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'כחול',
    vocalization: 'כחול',
    translation: 'Синий',
    transcription: 'кахОль',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'שחור',
    vocalization: 'שחור',
    translation: 'Черный',
    transcription: 'шахОр',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: `ירוק`,
    vocalization: `ירוק`,
    translation: 'Зеленый',
    transcription: 'ярОк',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'צהוב',
    vocalization: 'צהוב',
    translation: 'Желтый',
    transcription: 'цехОв',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'חום',
    vocalization: 'חום',
    translation: 'Коричневый',
    transcription: 'хум',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'אדום',
    vocalization: 'אדום',
    translation: 'Красный',
    transcription: 'адУм',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'אפור',
    vocalization: 'אפור',
    translation: 'Серый',
    transcription: 'афОр',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'ורוד',
    vocalization: 'ורוד',
    translation: 'Розовый',
    transcription: 'варОд',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'סגול',
    vocalization: 'סגול',
    translation: 'Фиолетовый',
    transcription: 'сагУл',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'כתום',
    vocalization: 'כתום',
    translation: 'Оранжевый',
    transcription: 'катОм',
    ruTopic: ['Прилагательные', 'Цвета'],
    enTopic: ['Adjectives', 'Colors'],
  },
  {
    word: 'לוֹמֵד',
    vocalization: 'לוֹמֵד',
    translation: '(он) учится',
    enTranslation: 'learn',
    transcription: 'ломЭд',
    infinitive: {
      word: 'ללמוד',
      vocalization: 'ללמוד',
      translation: 'Учиться',
      transcription: 'лильмОд',
    },
    present: [
      {
        word: 'לומד',
        vocalization: 'לוֹמֵד',
        translation: 'учится',
        transcription: 'ломЕд',
      },
      {
        word: 'לומדת',
        vocalization: 'לומדת',
        translation: 'учится',
        transcription: 'ломЕдет',
      },
      {
        word: 'לומדים',
        vocalization: 'לומדים',
        translation: 'учатся',
        transcription: 'ломдИм',
      },
      {
        word: 'לומדות',
        vocalization: 'לומדות',
        translation: 'учатся',
        transcription: 'ломдОт',
      },
    ],
    ruTopic: ['Учеба', 'Глаголы'],
    enTopic: ['Study', 'Verbs'],
  },
  {
    word: 'עובד',
    vocalization: 'עוֹבֵד',
    translation: '(он) работает',
    enTranslation: 'work',
    transcription: 'овЭд',
    infinitive: {
      word: 'לעבוד',
      vocalization: 'לעבוד',
      translation: 'Работать',
      transcription: 'лаавОд',
    },
    present: [
      {
        word: 'עובד',
        vocalization: 'עוֹבֵד',
        translation: 'работает',
        transcription: 'овЭд',
      },
      {
        word: 'עובדת',
        vocalization: 'עובדת',
        translation: 'работает',
        transcription: 'овЭдэт',
      },
      {
        word: 'עובדים',
        vocalization: 'עובדים',
        translation: 'работают',
        transcription: 'овдИм',
      },
      {
        word: 'עובדות',
        vocalization: 'עובדות',
        translation: 'работают',
        transcription: 'овдОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'גר',
    vocalization: 'גר',
    translation: '(он) живет',
    enTranslation: 'live',
    transcription: 'гАр',
    infinitive: {
      word: 'לגור',
      vocalization: 'לגור',
      translation: 'Жить',
      transcription: 'лагУр',
    },
    present: [
      {
        word: 'גר',
        vocalization: 'גר',
        translation: 'живет',
        transcription: 'гАр',
      },
      {
        word: 'גרה',
        vocalization: 'גרה',
        translation: 'живет',
        transcription: 'гарА',
      },
      {
        word: 'גרים',
        vocalization: 'גרים',
        translation: 'живут',
        transcription: 'гарИм',
      },
      {
        word: 'גרות',
        vocalization: 'גרות',
        translation: 'живут',
        transcription: 'гарОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מדבר',
    vocalization: 'מדבר',
    translation: '(он) говорит',
    enTranslation: 'say',
    transcription: 'медабЭр',
    infinitive: {
      word: 'לדבר',
      vocalization: 'לדבר',
      translation: 'Говорить',
      transcription: 'ледабЕр',
    },
    present: [
      {
        word: 'מדבר',
        vocalization: 'מדבר',
        translation: 'говорит',
        transcription: 'медабЭр',
      },
      {
        word: 'מדברת',
        vocalization: 'מדברת',
        translation: 'говорит',
        transcription: 'медабЕрет',
      },
      {
        word: 'מדברים',
        vocalization: 'מדברים',
        translation: 'говорят',
        transcription: 'медабрИм',
      },
      {
        word: 'מדברות',
        vocalization: 'מדברות',
        translation: 'говорят',
        transcription: 'медабрОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מצטער',
    vocalization: 'מצטער',
    translation: '(он) огорчается',
    enTranslation: 'upset',
    transcription: 'мицтаЭр',
    infinitive: {
      word: 'להצטער',
      vocalization: 'להצטער',
      translation: 'Огорчаться',
      transcription: 'леhицтаЭр',
    },
    present: [
      {
        word: 'מצטער',
        vocalization: 'מצטער',
        translation: 'огорчается',
        transcription: 'мицтаЭр',
      },
      {
        word: 'מצטערת',
        vocalization: 'מצטערת',
        translation: 'огорчается',
        transcription: 'мицтаЭрэт',
      },
      {
        word: 'מצטערים',
        vocalization: 'מצטערים',
        translation: 'огорчаются',
        transcription: 'мицтаэрИм',
      },
      {
        word: 'מצטערות',
        vocalization: 'מצטערות',
        translation: 'огорчаются',
        transcription: 'мицтаэрОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'יודע',
    vocalization: 'יודע',
    translation: '(он) знает',
    enTranslation: 'know',
    transcription: 'йодЭа',
    infinitive: {
      word: 'לדעת',
      vocalization: 'לדעת',
      translation: 'Знать',
      transcription: 'ладаАт',
    },
    present: [
      {
        word: 'יודע',
        vocalization: 'יודע',
        translation: 'знает',
        transcription: 'йодЭа',
      },
      {
        word: 'יודעת',
        vocalization: 'יודעת',
        translation: 'знает',
        transcription: 'йодаАт',
      },
      {
        word: 'יודעים',
        vocalization: 'יודעים',
        translation: 'знают',
        transcription: 'йодИм',
      },
      {
        word: 'יודעות',
        vocalization: 'יודעות',
        translation: 'знают',
        transcription: 'йодОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מבין',
    vocalization: 'מבין',
    translation: '(он) понимает',
    enTranslation: 'understand',
    transcription: 'мевИн',
    infinitive: {
      word: 'להבין',
      vocalization: 'להבין',
      translation: 'Понимать',
      transcription: 'леhавИн',
    },
    present: [
      {
        word: 'מבין',
        vocalization: 'מבין',
        translation: 'понимает',
        transcription: 'мевИн',
      },
      {
        word: 'מבינה',
        vocalization: 'מבינה',
        translation: 'понимает',
        transcription: 'мевинА',
      },
      {
        word: 'מבינים',
        vocalization: 'מבינים',
        translation: 'понимают',
        transcription: 'мевинИм',
      },
      {
        word: 'מבינות',
        vocalization: 'מבינות',
        translation: 'понимают',
        transcription: 'мевинОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'כותב',
    vocalization: 'כותב',
    translation: '(он) пишет',
    enTranslation: 'write',
    transcription: 'котЭв',
    infinitive: {
      word: 'לכתוב',
      vocalization: 'לכתוב',
      translation: 'Писать',
      transcription: 'лихтОв',
    },
    present: [
      {
        word: 'כותב',
        vocalization: 'כותב',
        translation: 'пишет',
        transcription: 'котЭв',
      },
      {
        word: 'כותבת',
        vocalization: 'כותבת',
        translation: 'пишет',
        transcription: 'котЭвет',
      },
      {
        word: 'כותבים',
        vocalization: 'כותבים',
        translation: 'пишут',
        transcription: 'котвИм',
      },
      {
        word: 'בותבות',
        vocalization: 'בותבות',
        translation: 'пишут',
        transcription: 'котвОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'קורא',
    vocalization: 'קורא',
    translation: '(он) читает',
    enTranslation: 'read',
    transcription: 'корЭ',
    infinitive: {
      word: 'לקרוא',
      vocalization: 'לקרוא',
      translation: 'Читать',
      transcription: 'лекрОа',
    },
    present: [
      {
        word: 'קורא',
        vocalization: 'קורא',
        translation: 'читает',
        transcription: 'корЭ',
      },
      {
        word: 'קוראת',
        vocalization: 'קוראת',
        translation: 'читает',
        transcription: 'корЭт',
      },
      {
        word: 'קוראים',
        vocalization: 'קוראים',
        translation: 'читают',
        transcription: 'коръИм',
      },
      {
        word: 'קורות',
        vocalization: 'קורות',
        translation: 'читают',
        transcription: 'коръОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'נמצא',
    vocalization: 'נִמְצָא',
    translation: '(он) находится',
    enTranslation: 'is',
    transcription: 'нимцА',
    infinitive: {
      word: 'להמצא',
      vocalization: 'להמצא',
      translation: 'Находиться',
      transcription: 'леhимацЕ',
    },
    present: [
      {
        word: 'נמצא',
        vocalization: 'נִמְצָא',
        translation: 'находится',
        transcription: 'нимцА',
      },
      {
        word: 'נמצאת',
        vocalization: 'נמצאת',
        translation: 'находится',
        transcription: 'нимцЕт',
      },
      {
        word: 'נמצאים',
        vocalization: 'נמצאים',
        translation: 'находятся',
        transcription: 'нимцаИм',
      },
      {
        word: 'נמצאות',
        vocalization: 'נמצאות',
        translation: 'находятся',
        transcription: 'нимцаОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'חסר',
    vocalization: 'חסר',
    translation: '(он) отсутствует',
    enTranslation: 'absent',
    transcription: 'хасЭр',
    infinitive: {
      word: 'לחסור',
      vocalization: 'לחסור',
      translation: 'Отсутствовать',
      transcription: 'лахсОр',
    },
    present: [
      {
        word: 'חסר',
        vocalization: 'חסר',
        translation: 'отсутствует',
        transcription: 'хасЭр',
      },
      {
        word: 'חסרה',
        vocalization: 'חסרה',
        translation: 'отсутствует',
        transcription: 'хасерА',
      },
      {
        word: 'חסרים',
        vocalization: 'חסרים',
        translation: 'отсутствуют',
        transcription: 'хасерИм',
      },
      {
        word: 'חסרות',
        vocalization: 'חסרות',
        translation: 'отсутствуют',
        transcription: 'хасерОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'הולך',
    vocalization: 'הולך',
    translation: '(он) идет пешком',
    transcription: 'олЭх',
    enTranslation: 'walk',
    infinitive: {
      word: 'ללכת',
      vocalization: 'ללכת',
      translation: 'Идти пешком',
      transcription: '',
    },
    present: [
      {
        word: 'הולך',
        vocalization: 'הולך',
        translation: 'идет пешком',
        transcription: 'hолЭх',
      },
      {
        word: 'הולכה',
        vocalization: 'הולכה',
        translation: 'идет пешком',
        transcription: 'hолЭхет',
      },
      {
        word: 'הוךכים',
        vocalization: 'הוךכים',
        translation: 'идут пешком',
        transcription: 'hолхИм',
      },
      {
        word: 'הולכות',
        vocalization: 'הולכות',
        translation: 'идут пешком',
        transcription: 'hолхОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'בא',
    vocalization: 'בא',
    translation: '(он) приходит',
    enTranslation: 'come',
    transcription: 'бА',
    infinitive: {
      word: 'לבוא',
      vocalization: 'לבוא',
      translation: 'Приходить',
      transcription: 'лавО',
    },
    present: [
      {
        word: 'בא',
        vocalization: 'בא',
        translation: 'приходит',
        transcription: 'бА',
      },
      {
        word: 'באה',
        vocalization: 'באה',
        translation: 'приходит',
        transcription: 'бАа',
      },
      {
        word: 'באים',
        vocalization: 'באים',
        translation: 'приходят',
        transcription: 'баИм',
      },
      {
        word: 'באות',
        vocalization: 'באות',
        translation: 'приходят',
        transcription: 'баОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'עונה',
    vocalization: 'עונה',
    translation: '(он) отвечает',
    enTranslation: 'unswer',
    transcription: 'онЭ',
    infinitive: {
      word: 'לענות',
      vocalization: 'לענות',
      translation: 'Отвечать',
      transcription: 'лаанОт',
    },
    present: [
      {
        word: 'עונה',
        vocalization: 'עונה',
        translation: 'отвечает',
        transcription: 'онЭ',
      },
      {
        word: 'עונה',
        vocalization: 'עונה',
        translation: 'отвечает',
        transcription: 'онА',
      },
      {
        word: 'עונים',
        vocalization: 'עונים',
        translation: 'отвечают',
        transcription: 'онИм',
      },
      {
        word: 'עונות',
        vocalization: 'עונות',
        translation: 'отвечают',
        transcription: 'онОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מקשיב',
    vocalization: 'מקשיב',
    translation: '(он) слушает',
    enTranslation: 'listen',
    transcription: 'макшИв',
    infinitive: {
      word: 'להקשיב',
      vocalization: 'להקשיב',
      translation: 'Слушать',
      transcription: 'леhакшив',
    },
    present: [
      {
        word: 'מקשיב',
        vocalization: 'מקשיב',
        translation: 'слушает',
        transcription: 'макшИв',
      },
      {
        word: 'מקשיבה',
        vocalization: 'מקשיבה',
        translation: 'слушает',
        transcription: 'макшивА',
      },
      {
        word: 'מקשיבים',
        vocalization: 'מקשיבים',
        translation: 'слушают',
        transcription: 'макшивИм',
      },
      {
        word: 'מקשיבות',
        vocalization: 'מקשיבות',
        translation: 'слушают',
        transcription: 'макшивОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מטייל',
    vocalization: 'מטייל',
    translation: '(он) гуляет/путешествует',
    enTranslation: 'travel',
    transcription: 'митайЭл',
    infinitive: {
      word: 'לטיל',
      vocalization: 'לטיל',
      translation: 'Гулять/путешествовать',
      transcription: 'летайЕль',
    },
    present: [
      {
        word: 'מטייל',
        vocalization: 'מטייל',
        translation: 'гуляет/путешествует',
        transcription: 'митайЭл',
      },
      {
        word: 'מטיילת',
        vocalization: 'מטיילת',
        translation: 'гуляет/путешествует',
        transcription: 'митайЭлет',
      },
      {
        word: 'מטיילים',
        vocalization: 'מטיילים',
        translation: 'гуляют/путешествуют',
        transcription: 'митайлИм',
      },
      {
        word: 'מטיילות',
        vocalization: 'מטיילות',
        translation: 'гуляют/путешествуют',
        transcription: 'митайлОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מטפל',
    vocalization: 'מטפל',
    translation: '(он) ухаживает, заботится',
    enTranslation: 'care',
    transcription: 'мэтапЭл',
    infinitive: {
      word: 'לטפל',
      vocalization: 'לטפל',
      translation: 'Ухаживать',
      transcription: 'летапЕль',
    },
    present: [
      {
        word: 'מטפל',
        vocalization: 'מטפל',
        translation: 'ухаживает',
        transcription: 'мэтапЕл',
      },
      {
        word: 'מטפלת',
        vocalization: 'מטפלת',
        translation: 'ухаживает',
        transcription: 'метапЕлет',
      },
      {
        word: 'מטפלים',
        vocalization: 'מטפלים',
        translation: 'ухаживают',
        transcription: 'метаплИм',
      },
      {
        word: 'מטפלות',
        vocalization: 'מטפלות',
        translation: 'ухаживают',
        transcription: 'метаплОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'נשאר',
    vocalization: 'נשאר',
    translation: '(он) остается',
    enTranslation: 'stay',
    transcription: 'нишАр',
    infinitive: {
      word: 'להישאר',
      vocalization: 'להישאר',
      translation: 'Оставаться',
      transcription: 'ЛеhишаЭр',
    },
    present: [
      {
        word: 'נשאר',
        vocalization: 'נשאר',
        translation: 'остается',
        transcription: 'нишАр',
      },
      {
        word: 'נשארת',
        vocalization: 'נשארת',
        translation: 'остается',
        transcription: 'нишЭрет',
      },
      {
        word: 'נשארים',
        vocalization: 'נשארים',
        translation: 'остаются',
        transcription: 'нишарИм',
      },
      {
        word: 'נשארות',
        vocalization: 'נשארות',
        translation: 'остаются',
        transcription: 'нишарОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'חושב',
    vocalization: 'חוֹשֵׁב',
    translation: '(он) думает',
    enTranslation: 'think',
    transcription: 'хошЕв',
    infinitive: {
      word: 'לחשןב',
      vocalization: 'לחשןב',
      translation: 'Думать',
      transcription: 'лахшОв',
    },
    present: [
      {
        word: 'חושב',
        vocalization: 'חוֹשֵׁב',
        translation: 'думает',
        transcription: 'хошЕв',
      },
      {
        word: 'חושבת',
        vocalization: 'חושבת',
        translation: 'думает',
        transcription: 'хошЕвет',
      },
      {
        word: 'חושבים',
        vocalization: 'חושבים',
        translation: 'думают',
        transcription: 'хошвИм',
      },
      {
        word: 'חושבות',
        vocalization: 'חושבות',
        translation: 'думают',
        transcription: 'хошвОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'נוסע',
    vocalization: 'נוֹסֵעַ',
    translation: '(он) едет',
    enTranslation: 'ride',
    transcription: 'носЭа',
    infinitive: {
      word: 'לנסוע',
      vocalization: 'לנסוע',
      translation: 'Ехать',
      transcription: 'линсОа',
    },
    present: [
      {
        word: 'נוסע',
        vocalization: 'נוֹסֵעַ',
        translation: 'едет',
        transcription: 'носЭа',
      },
      {
        word: 'נוסעת',
        vocalization: 'נוסעת',
        translation: 'едет',
        transcription: 'носаАт',
      },
      {
        word: 'נוסעים',
        vocalization: 'נוסעים',
        translation: 'едут',
        transcription: 'носъИм',
      },
      {
        word: 'נוסעות',
        vocalization: 'נוסעות',
        translation: 'едут',
        transcription: 'носъОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'קונה',
    vocalization: 'קונה',
    translation: '(он) покупает',
    enTranslation: 'buy',
    transcription: 'конЭ',
    infinitive: {
      word: 'לקנות',
      vocalization: 'לקנות',
      translation: 'Покупать',
      transcription: 'ликнОт',
    },
    present: [
      {
        word: 'קונה',
        vocalization: 'קונה',
        translation: 'покупает',
        transcription: 'конЭ',
      },
      {
        word: 'קונה',
        vocalization: 'קונה',
        translation: 'покупает',
        transcription: 'конА',
      },
      {
        word: 'קונים',
        vocalization: 'קונים',
        translation: 'покупают',
        transcription: 'конИм',
      },
      {
        word: 'קונות',
        vocalization: 'קונות',
        translation: 'покупают',
        transcription: 'конОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'רואה',
    vocalization: 'רואה',
    translation: '(он) видит',
    enTranslation: 'see',
    transcription: 'роЭ',
    infinitive: {
      word: 'לראות',
      vocalization: 'לראות',
      translation: 'Видеть',
      transcription: 'лиръОт',
    },
    present: [
      {
        word: 'רואה',
        vocalization: 'רואה',
        translation: 'видит',
        transcription: 'роЭ',
      },
      {
        word: 'רואה',
        vocalization: 'רואה',
        translation: 'видит',
        transcription: 'роА',
      },
      {
        word: 'רואים',
        vocalization: 'רואים',
        translation: 'видят',
        transcription: 'роИм',
      },
      {
        word: 'רואות',
        vocalization: 'רואות',
        translation: 'видят',
        transcription: 'роОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'עושה',
    vocalization: 'עושה',
    translation: '(он) делает',
    enTranslation: 'do',
    transcription: 'осЭ',
    infinitive: {
      word: 'לעשות',
      vocalization: 'לעשות',
      translation: 'Делать',
      transcription: 'лаасОт',
    },
    present: [
      {
        word: 'עושה',
        vocalization: 'עושה',
        translation: 'делает',
        transcription: 'осЭ',
      },
      {
        word: 'עושה',
        vocalization: 'עושה',
        translation: 'делает',
        transcription: 'осА',
      },
      {
        word: 'עושים',
        vocalization: 'עושים',
        translation: 'делают',
        transcription: 'осИм',
      },
      {
        word: 'עושות',
        vocalization: 'עושות',
        translation: 'делают',
        transcription: 'осОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'אוכל',
    vocalization: 'אוכל',
    translation: '(он) ест',
    enTranslation: 'eat',
    transcription: 'охЭль',
    infinitive: {
      word: 'לאכול',
      vocalization: 'לאכול',
      translation: 'Есть',
      transcription: 'лээхОль',
    },
    present: [
      {
        word: 'אוכל',
        vocalization: 'אוכל',
        translation: 'ест',
        transcription: 'охЭль',
      },
      {
        word: 'אוכלת',
        vocalization: 'אוכלת',
        translation: 'ест',
        transcription: 'охЭлет',
      },
      {
        word: 'אוכלים',
        vocalization: 'אוכלים',
        translation: 'едят',
        transcription: 'охлИм',
      },
      {
        word: 'אוכלות',
        vocalization: 'אוכלות',
        translation: 'едят',
        transcription: 'охлОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'שותה',
    vocalization: 'שותה',
    translation: '(он) пьет',
    enTranslation: 'drink',
    transcription: 'шотЭ',
    infinitive: {
      word: 'לשתות',
      vocalization: 'לשתות',
      translation: 'Пить',
      transcription: 'лиштОт',
    },
    present: [
      {
        word: 'שותה',
        vocalization: 'שותה',
        translation: 'пьет',
        transcription: 'шотЭ',
      },
      {
        word: 'שותה',
        vocalization: 'שותה',
        translation: 'пьет',
        transcription: 'шотА',
      },
      {
        word: 'שותים',
        vocalization: 'שותים',
        translation: 'пьют',
        transcription: 'шотИм',
      },
      {
        word: 'שותות',
        vocalization: 'שותות',
        translation: 'пьют',
        transcription: 'шотОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'יושב',
    vocalization: 'יושב',
    translation: '(он) сидит',
    enTranslation: 'sit',
    transcription: 'йошЕв',
    infinitive: {
      word: 'לשבת',
      vocalization: 'לשבת',
      translation: 'Сидеть',
      transcription: 'лашЭвед',
    },
    present: [
      {
        word: 'יושב',
        vocalization: 'יושב',
        translation: 'сидит',
        transcription: 'йошЕв',
      },
      {
        word: 'יושבת',
        vocalization: 'יושבת',
        translation: 'сидит',
        transcription: 'йошЕвед',
      },
      {
        word: 'יושבים',
        vocalization: 'יושבים',
        translation: 'сидят',
        transcription: 'йошвИм',
      },
      {
        word: 'יושבות',
        vocalization: 'יושבות',
        translation: 'сидят',
        transcription: 'йошвОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'חי',
    vocalization: 'חי',
    translation: '(он) живет',
    enTranslation: 'alive',
    transcription: 'хАй',
    infinitive: {
      word: 'לחיות',
      vocalization: 'לחיות',
      translation: 'Жить',
      transcription: 'лихйОт',
    },
    present: [
      {
        word: 'חי',
        vocalization: 'חי',
        translation: 'живет',
        transcription: 'хАй',
      },
      {
        word: 'חיה',
        vocalization: 'חיה',
        translation: 'живет',
        transcription: 'хаЯ',
      },
      {
        word: 'חיים',
        vocalization: 'חיים',
        translation: 'живут',
        transcription: 'хайИм',
      },
      {
        word: 'חיות',
        vocalization: 'חיות',
        translation: 'живут',
        transcription: 'хайОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'משחק',
    vocalization: 'משחק',
    translation: '(он) играет',
    enTranslation: 'play',
    transcription: 'месахЕк',
    infinitive: {
      word: 'לשחק',
      vocalization: 'לשחק',
      translation: 'Играть',
      transcription: 'лесахЕк',
    },
    present: [
      {
        word: 'משחק',
        vocalization: 'משחק',
        translation: 'играет',
        transcription: 'месахЕк',
      },
      {
        word: 'משחקת',
        vocalization: 'משחקת',
        translation: 'играет',
        transcription: 'месахЕкет',
      },
      {
        word: 'משחקים',
        vocalization: 'משחקים',
        translation: 'играют',
        transcription: 'месахакИм',
      },
      {
        word: 'משחקות',
        vocalization: 'משחקות',
        translation: 'играют',
        transcription: 'месахакОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מרגיש',
    vocalization: 'מרגיש',
    translation: '(он) чувствует',
    enTranslation: 'feel',
    transcription: 'маргИш',
    infinitive: {
      word: 'להרגיש',
      vocalization: 'להרגיש',
      translation: 'Чувствовать',
      transcription: 'леhаргИш',
    },
    present: [
      {
        word: 'מרגיש',
        vocalization: 'מרגיש',
        translation: 'чувствует',
        transcription: 'маргИш',
      },
      {
        word: 'מרגישה',
        vocalization: 'מרגישה',
        translation: 'чувствует',
        transcription: 'маргишА',
      },
      {
        word: 'מרגישים',
        vocalization: 'מרגישים',
        translation: 'чувствуют',
        transcription: 'маргишИм',
      },
      {
        word: 'מרגישות',
        vocalization: 'מרגישות',
        translation: 'чувствуют',
        transcription: 'маргишОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'רוצה',
    vocalization: 'רוצה',
    translation: '(он) хочет',
    enTranslation: 'want',
    transcription: 'роцЭ',
    infinitive: {
      word: 'לרצות',
      vocalization: 'לרצות',
      translation: 'Хотеть',
      transcription: 'лирцОт',
    },
    present: [
      {
        word: 'רוצה',
        vocalization: 'רוצה',
        translation: 'хочет',
        transcription: 'роцЭ',
      },
      {
        word: 'רוצה',
        vocalization: 'רוצה',
        translation: 'хочет',
        transcription: 'роцА',
      },
      {
        word: 'רוצים',
        vocalization: 'רוצים',
        translation: 'хотят',
        transcription: 'роцИм',
      },
      {
        word: 'רוצות',
        vocalization: 'רוצות',
        translation: 'хотят',
        transcription: 'роцОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'בין',
    vocalization: 'בין',
    translation: 'Между',
    transcription: 'бЕйн',
    ruTopic: ['Предлоги'],
    enTopic: ['Prepositions'],
  },
  {
    word: 'על',
    vocalization: 'על',
    translation: 'На',
    transcription: 'аль',
    ruTopic: ['Предлоги'],
    enTopic: ['Prepositions'],
  },
  {
    word: 'על-יד',
    vocalization: 'על-יד',
    translation: 'Возле',
    transcription: 'аль-яд',
    ruTopic: ['Предлоги'],
    enTopic: ['Prepositions'],
  },
  {
    word: 'מול',
    vocalization: 'מול',
    translation: 'напротив',
    transcription: 'муль',
    ruTopic: ['Предлоги'],
    enTopic: ['Prepositions'],
  },
  {
    word: 'תחת',
    vocalization: 'תַּחַת',
    translation: 'Под, снизу',
    transcription: 'тАхат',
    ruTopic: ['Предлоги'],
    enTopic: ['Prepositions'],
  },
  {
    word: 'עם',
    vocalization: 'עם',
    translation: 'С',
    transcription: 'Им',
    ruTopic: ['Предлоги'],
    enTopic: ['Prepositions'],
  },
  {
    word: 'תייר',
    vocalization: 'תייר',
    translation: 'Турист',
    transcription: 'таЯр',
    ruTopic: ['Путешествия', 'Разное'],
    enTopic: ['Travel', 'Other'],
  },
  {
    word: 'נשיא',
    vocalization: 'נָשִׂיא',
    translation: 'Президент',
    transcription: 'насИ',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'ראש הממשלה',
    vocalization: 'ראש הממשלה',
    translation: 'Премьер-министр, глава првительства',
    transcription: 'рОш а-мемшалА',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'בירה',
    vocalization: 'בִּירָה',
    translation: 'Столица',
    transcription: 'бирА',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'עיר',
    vocalization: 'עיר',
    translation: 'Город',
    transcription: 'Ир',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'ארץ',
    vocalization: 'אֶרֶץ',
    translation: 'Государство',
    transcription: 'Эрец',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'עם',
    vocalization: 'עם',
    translation: 'Народ',
    transcription: 'Ам',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר',
    vocalization: 'שר',
    translation: 'Министр',
    transcription: 'сАр',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'המנון',
    vocalization: 'המנון',
    translation: 'Гимн',
    transcription: 'химнОн',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'סמל',
    vocalization: 'סֵמֶל',
    translation: 'Символ',
    transcription: 'сЕмель',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'מנורה',
    vocalization: 'מנורה',
    translation: 'Менора',
    transcription: 'менорА',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'מגן דוד',
    vocalization: 'מגן דוד',
    translation: 'Щит Давида',
    transcription: 'магЕн Давид',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'התקוה',
    vocalization: 'התקוה',
    translation: 'Надежда',
    transcription: 'атИква',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'צבא',
    vocalization: 'צבא',
    translation: 'Армия',
    transcription: 'цвА',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר הביטחון',
    vocalization: 'שר הביטחון',
    translation: 'Министр обороны',
    transcription: 'Сар абитахОн',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר האוצר',
    vocalization: 'שר האוצר',
    translation: 'Министр финансов',
    transcription: 'сАр аоцАр',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר החינוך',
    vocalization: 'שר החינוך',
    translation: 'Министр образования',
    transcription: 'сАр ахинУх',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר החוץ',
    vocalization: 'שר החוץ',
    translation: 'Министр иностранных дел',
    transcription: 'сАр ахУц',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר הפנים',
    vocalization: 'שר הפנים',
    translation: 'Министр внутренних дел',
    transcription: 'сАр апнИм',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'שר הקליטה',
    vocalization: 'שר הקליטה',
    translation: 'Министр абсорбции',
    transcription: 'сАр аклитА',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'צבא',
    vocalization: 'צבא',
    translation: 'Армия',
    transcription: 'цавА',
    ruTopic: ['Государственность'],
    enTopic: ['Statehood'],
  },
  {
    word: 'טלפון',
    vocalization: 'טלפון',
    translation: 'Телефон',
    transcription: 'тэлэфОн',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'טלוויזיה',
    vocalization: 'טלוויזיה',
    translation: 'Телевизор',
    transcription: 'ТэлэвИйзиа',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'עולה חדש',
    vocalization: 'עולה חדש',
    translation: 'Новый репатриант',
    transcription: 'олЕ хадАш',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'קצת',
    vocalization: 'קצת',
    translation: 'Ненадолго',
    transcription: 'Кцат',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'גם',
    vocalization: 'גם',
    translation: 'Тоже',
    transcription: 'гам',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כן',
    vocalization: 'כן',
    translation: 'Да',
    transcription: 'кен',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'לא',
    vocalization: 'לא',
    translation: 'нет',
    transcription: 'лЁ',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'נכון',
    vocalization: 'נכון',
    translation: 'Верно',
    transcription: 'нахОн',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'שולחן',
    vocalization: 'שולחן',
    translation: 'Стол',
    transcription: 'шулхАн',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'תמןנה',
    vocalization: 'תמונה',
    translation: 'Картина',
    transcription: 'тмунА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'עט',
    vocalization: 'עט',
    translation: 'Ручка',
    transcription: 'эт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'עיטון',
    vocalization: 'עימון',
    translation: 'Газета',
    transcription: 'итОн',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'סיפור',
    vocalization: 'סיפור',
    translation: 'История',
    transcription: 'сипУр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'ספר',
    vocalization: 'ספר',
    translation: 'Книга',
    transcription: 'сЭфэр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מכתב',
    vocalization: 'מכתב',
    translation: 'Письмо',
    transcription: 'михтАв',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'תיק',
    vocalization: 'תיק',
    translation: 'Сумка',
    transcription: 'тик',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'שאלה',
    vocalization: 'שאלה',
    translation: 'Вопрос',
    transcription: 'шЭЭла',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'תשובה',
    vocalization: 'תשובה',
    translation: 'Ответ',
    transcription: 'тшувА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מחשבה',
    vocalization: 'מחשבה',
    translation: 'Мысль',
    transcription: 'махшэвА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מחשב',
    vocalization: 'מחשב',
    translation: 'Компьютер',
    transcription: 'махшЕв',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מאוד',
    vocalization: 'מאוד',
    translation: 'Очень',
    transcription: 'мэОд',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'נעים',
    vocalization: 'נעים',
    translation: 'Приятно',
    transcription: 'наИм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'בחורה',
    vocalization: 'בחורה',
    translation: 'Девушка',
    transcription: 'бахурА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'בחור',
    vocalization: 'בחור',
    translation: 'Парень',
    transcription: 'бахУр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },

  {
    word: 'חופש',
    vocalization: 'חופש',
    translation: 'Отпуск',
    transcription: 'хОфэш',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'רק',
    vocalization: 'רק',
    translation: 'Только',
    transcription: 'рак',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'עכשיב',
    vocalization: 'עכשיב',
    translation: 'Сейчас',
    transcription: 'ахшАв',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'דיאלוג',
    vocalization: 'דיאלוג',
    translation: 'Диалог',
    transcription: 'диалог',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מפה',
    vocalization: 'מפה',
    translation: 'Карта',
    transcription: 'мапА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'קיר',
    vocalization: 'קיר',
    translation: 'Стена',
    transcription: 'кир',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'בירה',
    vocalization: 'בירה',
    translation: 'Столица',
    transcription: 'бирА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'שפה',
    vocalization: 'שפה',
    translation: 'Язык',
    transcription: 'сафА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מגדל',
    vocalization: 'מגדל',
    translation: 'Башня',
    transcription: 'мигдАль',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'תוכניות',
    vocalization: 'תוכניות',
    translation: 'Передачи',
    transcription: 'тохньОт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'ים',
    vocalization: 'ים',
    translation: 'Море',
    transcription: 'Ям',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מכונית',
    vocalization: 'מכונית',
    translation: 'Машина',
    transcription: 'мэхонИт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'לוח',
    vocalization: 'לוח',
    translation: 'Доска',
    transcription: 'лУах',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כסף',
    vocalization: 'כסף',
    translation: 'Деньги',
    transcription: 'кЭсэф',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'עיפרון',
    vocalization: 'עִיפָּרוֹן',
    translation: 'Карандаш',
    transcription: 'ипарОн',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מחברת',
    vocalization: 'מחברת',
    translation: 'Тетрадь',
    transcription: 'махбЭрэт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'היום',
    vocalization: 'הַיּוֹם',
    translation: 'Сегодня',
    transcription: 'хайОм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'פקס',
    vocalization: 'פַקְס',
    translation: 'Факс',
    transcription: 'фАкс',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'גברת',
    vocalization: 'גְּבֶרֶת',
    translation: 'Госпожа',
    transcription: 'гвЭрэт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מר',
    vocalization: 'מַר',
    translation: 'Господин',
    transcription: 'мАр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'לב',
    vocalization: 'לֵב',
    translation: 'Сердце',
    transcription: 'лЕв',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'אש',
    vocalization: 'אֵשׁ',
    translation: 'Огонь',
    transcription: 'Эш',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'נפט',
    vocalization: 'נֵפְט',
    translation: 'Нефть',
    transcription: 'нЭфт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כיסא',
    vocalization: 'כיסא',
    translation: 'Стул',
    transcription: 'кисЭ',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'רדיו',
    vocalization: 'רדיו',
    translation: 'Радио',
    transcription: 'рАдио',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'הרבה',
    vocalization: 'הַרְבֵּה',
    translation: 'Много',
    transcription: 'арбЭ',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'בחוץ',
    vocalization: 'בחוץ',
    translation: 'Снаружи',
    transcription: 'ба-хуц',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כי',
    vocalization: 'כי',
    translation: 'Потому что',
    transcription: 'кИ',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מפני שאני',
    vocalization: 'מפני שאני',
    translation: 'Потому что',
    transcription: 'мифнЭй шенИ',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'הפסקה',
    vocalization: 'הַפְסָקָה',
    translation: 'Перерыв',
    transcription: 'афсакА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'טיול',
    vocalization: 'טיול',
    translation: 'Путешествие',
    transcription: 'тиУль',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'אוטובוס',
    vocalization: 'אוֹטוֹבּוּס',
    translation: 'Автобус',
    transcription: 'Отобус',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'קופת-חולים',
    vocalization: 'קופת-חולים',
    translation: 'Больничная касса',
    transcription: 'купАт холИм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'ברגל',
    vocalization: 'ברגל',
    translation: 'Пешком',
    transcription: 'ба-регель',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'אם',
    vocalization: 'אם',
    translation: 'Если',
    transcription: 'Им',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'חדשות',
    vocalization: 'חדשות',
    translation: 'Новости',
    transcription: 'хадашОт',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כמו תמיד',
    vocalization: 'כמו תמיד',
    translation: 'Как всегда',
    transcription: 'кмО тамИд',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'תמיד',
    vocalization: 'תמיד',
    translation: 'Всегда',
    transcription: 'тамИд',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מפתח',
    vocalization: 'מפתח',
    translation: 'Ключ',
    transcription: 'мафтЭах',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'אבל',
    vocalization: 'אבל',
    translation: 'Но',
    transcription: 'авАль',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כסף',
    vocalization: 'כסף',
    translation: 'Деньги',
    transcription: 'кЕсеф',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כל',
    vocalization: 'כל',
    translation: 'Все',
    transcription: 'кАль',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'לפעמים',
    vocalization: 'לפעמים',
    translation: 'Иногда',
    transcription: 'лифамИм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'בדרך כלל',
    vocalization: 'בדרך כלל',
    translation: 'В общем',
    transcription: 'бэдЭрэх клАль',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'איש',
    vocalization: 'איש',
    translation: 'Человек',
    transcription: 'Иш',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'נהג',
    vocalization: 'נהג',
    translation: 'Водитель',
    transcription: 'нАг',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מנהיג',
    vocalization: 'מנהיג',
    translation: 'Лидер',
    transcription: 'манхИг',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מצב משפחתי',
    vocalization: 'מצב משפחתי',
    translation: 'Семейное положение',
    transcription: 'мацАв мишпахтИ',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'צדיק',
    vocalization: 'צדיק',
    translation: 'Праведник',
    transcription: 'цадИк',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'עונה',
    vocalization: 'עונה',
    translation: 'Сезон',
    transcription: 'онА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'פגישה',
    vocalization: 'פגישה',
    translation: 'Встреча',
    transcription: 'пгишА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'חדר',
    vocalization: 'חדר',
    translation: 'Комната',
    transcription: 'хЕдер',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'רהיטים',
    vocalization: 'רָהִיטִים',
    translation: 'Мебель',
    transcription: 'раhитИм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'שם',
    vocalization: 'שם',
    translation: 'Имя',
    transcription: 'шЕм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מילה',
    vocalization: 'מילה',
    translation: 'Слово',
    transcription: 'милА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'שנה',
    vocalization: 'שנה',
    translation: 'Год',
    transcription: 'шанА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מחר',
    vocalization: 'מחר',
    translation: 'Завтра',
    transcription: 'махАр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כבר',
    vocalization: 'כבר',
    translation: 'Уже',
    transcription: 'квАр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'דווקא',
    vocalization: 'דווקא',
    translation: 'Именно',
    transcription: 'дАвка',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'אף פעם',
    vocalization: 'אף פעם',
    translation: 'Никогда',
    transcription: 'Аф паАм',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'נודניק',
    vocalization: 'נודניק',
    translation: 'Зануда',
    transcription: 'нУдник',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'אולי',
    vocalization: 'אולי',
    translation: 'Может быть',
    transcription: 'улАй',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'דואר',
    vocalization: 'דואר',
    translation: 'Почта',
    transcription: 'дОар',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'דרך',
    vocalization: 'דרך',
    translation: 'Дорога',
    transcription: 'дЭрех',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'ישר',
    vocalization: 'ישר',
    translation: 'Прямо',
    transcription: 'яшАр',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'שמאל',
    vocalization: 'שְׂמֹאל',
    translation: 'Лево',
    transcription: 'смОль',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מינה',
    vocalization: 'ימינ',
    translation: 'Право',
    transcription: 'ямИн',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'כאן',
    vocalization: 'כאן',
    translation: 'Здесь',
    transcription: 'кан',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מעטפה',
    vocalization: 'מעטפה',
    translation: 'Конверт',
    transcription: 'маатафА',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  {
    word: 'מקלחת',
    vocalization: 'מקלחת',
    translation: 'Душ',
    transcription: 'миклАхат',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },
  /*{
    word: '',
    vocalization: '',
    translation: '',
    transcription: '',
    ruTopic: ['Разное'],
    enTopic: ['Other'],
  },*/
];

export const verbs = [
  {
    word: 'לוֹמֵד',
    vocalization: 'לוֹמֵד',
    translation: '(он) учится',
    enTranslation: 'learn',
    transcription: 'ломЭд',
    infinitive: {
      word: 'ללמוד',
      vocalization: 'ללמוד',
      translation: 'Учиться',
      transcription: 'лильмОд',
    },
    present: [
      {
        word: 'לומד',
        vocalization: 'לוֹמֵד',
        translation: 'учится',
        transcription: 'ломЕд',
      },
      {
        word: 'לומדת',
        vocalization: 'לומדת',
        translation: 'учится',
        transcription: 'ломЕдет',
      },
      {
        word: 'לומדים',
        vocalization: 'לומדים',
        translation: 'учатся',
        transcription: 'ломдИм',
      },
      {
        word: 'לומדות',
        vocalization: 'לומדות',
        translation: 'учатся',
        transcription: 'ломдОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'עובד',
    vocalization: 'עוֹבֵד',
    translation: '(он) работает',
    enTranslation: 'work',
    transcription: 'овЭд',
    infinitive: {
      word: 'לעבוד',
      vocalization: 'לעבוד',
      translation: 'Работать',
      transcription: 'лаавОд',
    },
    present: [
      {
        word: 'עובד',
        vocalization: 'עוֹבֵד',
        translation: 'работает',
        transcription: 'овЭд',
      },
      {
        word: 'עובדת',
        vocalization: 'עובדת',
        translation: 'работает',
        transcription: 'овЭдэт',
      },
      {
        word: 'עובדים',
        vocalization: 'עובדים',
        translation: 'работают',
        transcription: 'овдИм',
      },
      {
        word: 'עובדות',
        vocalization: 'עובדות',
        translation: 'работают',
        transcription: 'овдОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'גר',
    vocalization: 'גר',
    translation: '(он) живет',
    enTranslation: 'live',
    transcription: 'гАр',
    infinitive: {
      word: 'לגור',
      vocalization: 'לגור',
      translation: 'Жить',
      transcription: 'лагУр',
    },
    present: [
      {
        word: 'גר',
        vocalization: 'גר',
        translation: 'живет',
        transcription: 'гАр',
      },
      {
        word: 'גרה',
        vocalization: 'גרה',
        translation: 'живет',
        transcription: 'гарА',
      },
      {
        word: 'גרים',
        vocalization: 'גרים',
        translation: 'живут',
        transcription: 'гарИм',
      },
      {
        word: 'גרות',
        vocalization: 'גרות',
        translation: 'живут',
        transcription: 'гарОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מדבר',
    vocalization: 'מדבר',
    translation: '(он) говорит',
    enTranslation: 'say',
    transcription: 'медабЭр',
    infinitive: {
      word: 'לדבר',
      vocalization: 'לדבר',
      translation: 'Говорить',
      transcription: 'ледабЕр',
    },
    present: [
      {
        word: 'מדבר',
        vocalization: 'מדבר',
        translation: 'говорит',
        transcription: 'медабЭр',
      },
      {
        word: 'מדברת',
        vocalization: 'מדברת',
        translation: 'говорит',
        transcription: 'медабЕрет',
      },
      {
        word: 'מדברים',
        vocalization: 'מדברים',
        translation: 'говорят',
        transcription: 'медабрИм',
      },
      {
        word: 'מדברות',
        vocalization: 'מדברות',
        translation: 'говорят',
        transcription: 'медабрОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מצטער',
    vocalization: 'מצטער',
    translation: '(он) огорчается',
    enTranslation: 'upset',
    transcription: 'мицтаЭр',
    infinitive: {
      word: 'להצטער',
      vocalization: 'להצטער',
      translation: 'Огорчаться',
      transcription: 'леhицтаЭр',
    },
    present: [
      {
        word: 'מצטער',
        vocalization: 'מצטער',
        translation: 'огорчается',
        transcription: 'мицтаЭр',
      },
      {
        word: 'מצטערת',
        vocalization: 'מצטערת',
        translation: 'огорчается',
        transcription: 'мицтаЭрэт',
      },
      {
        word: 'מצטערים',
        vocalization: 'מצטערים',
        translation: 'огорчаются',
        transcription: 'мицтаэрИм',
      },
      {
        word: 'מצטערות',
        vocalization: 'מצטערות',
        translation: 'огорчаются',
        transcription: 'мицтаэрОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'יודע',
    vocalization: 'יודע',
    translation: '(он) знает',
    enTranslation: 'know',
    transcription: 'йодЭа',
    infinitive: {
      word: 'לדעת',
      vocalization: 'לדעת',
      translation: 'Знать',
      transcription: 'ладаАт',
    },
    present: [
      {
        word: 'יודע',
        vocalization: 'יודע',
        translation: 'знает',
        transcription: 'йодЭа',
      },
      {
        word: 'יודעת',
        vocalization: 'יודעת',
        translation: 'знает',
        transcription: 'йодаАт',
      },
      {
        word: 'יודעים',
        vocalization: 'יודעים',
        translation: 'знают',
        transcription: 'йодИм',
      },
      {
        word: 'יודעות',
        vocalization: 'יודעות',
        translation: 'знают',
        transcription: 'йодОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מבין',
    vocalization: 'מבין',
    translation: '(он) понимает',
    enTranslation: 'understand',
    transcription: 'мевИн',
    infinitive: {
      word: 'להבין',
      vocalization: 'להבין',
      translation: 'Понимать',
      transcription: 'леhавИн',
    },
    present: [
      {
        word: 'מבין',
        vocalization: 'מבין',
        translation: 'понимает',
        transcription: 'мевИн',
      },
      {
        word: 'מבינה',
        vocalization: 'מבינה',
        translation: 'понимает',
        transcription: 'мевинА',
      },
      {
        word: 'מבינים',
        vocalization: 'מבינים',
        translation: 'понимают',
        transcription: 'мевинИм',
      },
      {
        word: 'מבינות',
        vocalization: 'מבינות',
        translation: 'понимают',
        transcription: 'мевинОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'כותב',
    vocalization: 'כותב',
    translation: '(он) пишет',
    enTranslation: 'write',
    transcription: 'котЭв',
    infinitive: {
      word: 'לכתוב',
      vocalization: 'לכתוב',
      translation: 'Писать',
      transcription: 'лихтОв',
    },
    present: [
      {
        word: 'כותב',
        vocalization: 'כותב',
        translation: 'пишет',
        transcription: 'котЭв',
      },
      {
        word: 'כותבת',
        vocalization: 'כותבת',
        translation: 'пишет',
        transcription: 'котЭвет',
      },
      {
        word: 'כותבים',
        vocalization: 'כותבים',
        translation: 'пишут',
        transcription: 'котвИм',
      },
      {
        word: 'בותבות',
        vocalization: 'בותבות',
        translation: 'пишут',
        transcription: 'котвОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'קורא',
    vocalization: 'קורא',
    translation: '(он) читает',
    enTranslation: 'read',
    transcription: 'корЭ',
    infinitive: {
      word: 'לקרוא',
      vocalization: 'לקרוא',
      translation: 'Читать',
      transcription: 'лекрОа',
    },
    present: [
      {
        word: 'קורא',
        vocalization: 'קורא',
        translation: 'читает',
        transcription: 'корЭ',
      },
      {
        word: 'קוראת',
        vocalization: 'קוראת',
        translation: 'читает',
        transcription: 'корЭт',
      },
      {
        word: 'קוראים',
        vocalization: 'קוראים',
        translation: 'читают',
        transcription: 'коръИм',
      },
      {
        word: 'קורות',
        vocalization: 'קורות',
        translation: 'читают',
        transcription: 'коръОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'נמצא',
    vocalization: 'נִמְצָא',
    translation: '(он) находится',
    enTranslation: 'is',
    transcription: 'нимцА',
    infinitive: {
      word: 'להמצא',
      vocalization: 'להמצא',
      translation: 'Находиться',
      transcription: 'леhимацЕ',
    },
    present: [
      {
        word: 'נמצא',
        vocalization: 'נִמְצָא',
        translation: 'находится',
        transcription: 'нимцА',
      },
      {
        word: 'נמצאת',
        vocalization: 'נמצאת',
        translation: 'находится',
        transcription: 'нимцЕт',
      },
      {
        word: 'נמצאים',
        vocalization: 'נמצאים',
        translation: 'находятся',
        transcription: 'нимцаИм',
      },
      {
        word: 'נמצאות',
        vocalization: 'נמצאות',
        translation: 'находятся',
        transcription: 'нимцаОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'חסר',
    vocalization: 'חסר',
    translation: '(он) отсутствует',
    enTranslation: 'absent',
    transcription: 'хасЭр',
    infinitive: {
      word: 'לחסור',
      vocalization: 'לחסור',
      translation: 'Отсутствовать',
      transcription: 'лахсОр',
    },
    present: [
      {
        word: 'חסר',
        vocalization: 'חסר',
        translation: 'отсутствует',
        transcription: 'хасЭр',
      },
      {
        word: 'חסרה',
        vocalization: 'חסרה',
        translation: 'отсутствует',
        transcription: 'хасерА',
      },
      {
        word: 'חסרים',
        vocalization: 'חסרים',
        translation: 'отсутствуют',
        transcription: 'хасерИм',
      },
      {
        word: 'חסרות',
        vocalization: 'חסרות',
        translation: 'отсутствуют',
        transcription: 'хасерОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'הולך',
    vocalization: 'הולך',
    translation: '(он) идет пешком',
    transcription: 'олЭх',
    enTranslation: 'walk',
    infinitive: {
      word: 'ללכת',
      vocalization: 'ללכת',
      translation: 'Идти пешком',
      transcription: 'лалЕхет',
    },
    present: [
      {
        word: 'הולך',
        vocalization: 'הולך',
        translation: 'идет пешком',
        transcription: 'hолЭх',
      },
      {
        word: 'הולכה',
        vocalization: 'הולכה',
        translation: 'идет пешком',
        transcription: 'hолЭхет',
      },
      {
        word: 'הוךכים',
        vocalization: 'הולכים',
        translation: 'идут пешком',
        transcription: 'hолхИм',
      },
      {
        word: 'הולכות',
        vocalization: 'הולכות',
        translation: 'идут пешком',
        transcription: 'hолхОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'בא',
    vocalization: 'בא',
    translation: '(он) приходит',
    enTranslation: 'come',
    transcription: 'бА',
    infinitive: {
      word: 'לבוא',
      vocalization: 'לבוא',
      translation: 'Приходить',
      transcription: 'лавО',
    },
    present: [
      {
        word: 'בא',
        vocalization: 'בא',
        translation: 'приходит',
        transcription: 'бА',
      },
      {
        word: 'באה',
        vocalization: 'באה',
        translation: 'приходит',
        transcription: 'бАа',
      },
      {
        word: 'באים',
        vocalization: 'באים',
        translation: 'приходят',
        transcription: 'баИм',
      },
      {
        word: 'באות',
        vocalization: 'באות',
        translation: 'приходят',
        transcription: 'баОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'עונה',
    vocalization: 'עונה',
    translation: '(он) отвечает',
    enTranslation: 'unswer',
    transcription: 'онЭ',
    infinitive: {
      word: 'לענות',
      vocalization: 'לענות',
      translation: 'Отвечать',
      transcription: 'лаанОт',
    },
    present: [
      {
        word: 'עונה',
        vocalization: 'עונה',
        translation: 'отвечает',
        transcription: 'онЭ',
      },
      {
        word: 'עונה',
        vocalization: 'עונה',
        translation: 'отвечает',
        transcription: 'онА',
      },
      {
        word: 'עונים',
        vocalization: 'עונים',
        translation: 'отвечают',
        transcription: 'онИм',
      },
      {
        word: 'עונות',
        vocalization: 'עונות',
        translation: 'отвечают',
        transcription: 'онОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מקשיב',
    vocalization: 'מקשיב',
    translation: '(он) слушает',
    enTranslation: 'listen',
    transcription: 'макшИв',
    infinitive: {
      word: 'להקשיב',
      vocalization: 'להקשיב',
      translation: 'Слушать',
      transcription: 'леhакшив',
    },
    present: [
      {
        word: 'מקשיב',
        vocalization: 'מקשיב',
        translation: 'слушает',
        transcription: 'макшИв',
      },
      {
        word: 'מקשיבה',
        vocalization: 'מקשיבה',
        translation: 'слушает',
        transcription: 'макшивА',
      },
      {
        word: 'מקשיבים',
        vocalization: 'מקשיבים',
        translation: 'слушают',
        transcription: 'макшивИм',
      },
      {
        word: 'מקשיבות',
        vocalization: 'מקשיבות',
        translation: 'слушают',
        transcription: 'макшивОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מטייל',
    vocalization: 'מטייל',
    translation: '(он) гуляет/путешествует',
    enTranslation: 'travel',
    transcription: 'митайЭл',
    infinitive: {
      word: 'לטיל',
      vocalization: 'לטיל',
      translation: 'Гулять/путешествовать',
      transcription: 'летайЕль',
    },
    present: [
      {
        word: 'מטייל',
        vocalization: 'מטייל',
        translation: 'гуляет/путешествует',
        transcription: 'митайЭл',
      },
      {
        word: 'מטיילת',
        vocalization: 'מטיילת',
        translation: 'гуляет/путешествует',
        transcription: 'митайЭлет',
      },
      {
        word: 'מטיילים',
        vocalization: 'מטיילים',
        translation: 'гуляют/путешествуют',
        transcription: 'митайлИм',
      },
      {
        word: 'מטיילות',
        vocalization: 'מטיילות',
        translation: 'гуляют/путешествуют',
        transcription: 'митайлОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מטפל',
    vocalization: 'מטפל',
    translation: '(он) ухаживает, заботится',
    enTranslation: 'care',
    transcription: 'мэтапЭл',
    infinitive: {
      word: 'לטפל',
      vocalization: 'לטפל',
      translation: 'Ухаживать',
      transcription: 'летапЕль',
    },
    present: [
      {
        word: 'מטפל',
        vocalization: 'מטפל',
        translation: 'ухаживает',
        transcription: 'мэтапЕл',
      },
      {
        word: 'מטפלת',
        vocalization: 'מטפלת',
        translation: 'ухаживает',
        transcription: 'метапЕлет',
      },
      {
        word: 'מטפלים',
        vocalization: 'מטפלים',
        translation: 'ухаживают',
        transcription: 'метаплИм',
      },
      {
        word: 'מטפלות',
        vocalization: 'מטפלות',
        translation: 'ухаживают',
        transcription: 'метаплОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'נשאר',
    vocalization: 'נשאר',
    translation: '(он) остается',
    enTranslation: 'stay',
    transcription: 'нишАр',
    infinitive: {
      word: 'להישאר',
      vocalization: 'להישאר',
      translation: 'Оставаться',
      transcription: 'ЛеhишаЭр',
    },
    present: [
      {
        word: 'נשאר',
        vocalization: 'נשאר',
        translation: 'остается',
        transcription: 'нишАр',
      },
      {
        word: 'נשארת',
        vocalization: 'נשארת',
        translation: 'остается',
        transcription: 'нишЭрет',
      },
      {
        word: 'נשארים',
        vocalization: 'נשארים',
        translation: 'остаются',
        transcription: 'нишарИм',
      },
      {
        word: 'נשארות',
        vocalization: 'נשארות',
        translation: 'остаются',
        transcription: 'нишарОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'חושב',
    vocalization: 'חוֹשֵׁב',
    translation: '(он) думает',
    enTranslation: 'think',
    transcription: 'хошЕв',
    infinitive: {
      word: 'לחשןב',
      vocalization: 'לחשןב',
      translation: 'Думать',
      transcription: 'лахшОв',
    },
    present: [
      {
        word: 'חושב',
        vocalization: 'חוֹשֵׁב',
        translation: 'думает',
        transcription: 'хошЕв',
      },
      {
        word: 'חושבת',
        vocalization: 'חושבת',
        translation: 'думает',
        transcription: 'хошЕвет',
      },
      {
        word: 'חושבים',
        vocalization: 'חושבים',
        translation: 'думают',
        transcription: 'хошвИм',
      },
      {
        word: 'חושבות',
        vocalization: 'חושבות',
        translation: 'думают',
        transcription: 'хошвОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'נוסע',
    vocalization: 'נוֹסֵעַ',
    translation: '(он) едет',
    enTranslation: 'ride',
    transcription: 'носЭа',
    infinitive: {
      word: 'לנסוע',
      vocalization: 'לנסוע',
      translation: 'Ехать',
      transcription: 'линсОа',
    },
    present: [
      {
        word: 'נוסע',
        vocalization: 'נוֹסֵעַ',
        translation: 'едет',
        transcription: 'носЭа',
      },
      {
        word: 'נוסעת',
        vocalization: 'נוסעת',
        translation: 'едет',
        transcription: 'носаАт',
      },
      {
        word: 'נוסעים',
        vocalization: 'נוסעים',
        translation: 'едут',
        transcription: 'носъИм',
      },
      {
        word: 'נוסעות',
        vocalization: 'נוסעות',
        translation: 'едут',
        transcription: 'носъОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'קונה',
    vocalization: 'קונה',
    translation: '(он) покупает',
    enTranslation: 'buy',
    transcription: 'конЭ',
    infinitive: {
      word: 'לקנות',
      vocalization: 'לקנות',
      translation: 'Покупать',
      transcription: 'ликнОт',
    },
    present: [
      {
        word: 'קונה',
        vocalization: 'קונה',
        translation: 'покупает',
        transcription: 'конЭ',
      },
      {
        word: 'קונה',
        vocalization: 'קונה',
        translation: 'покупает',
        transcription: 'конА',
      },
      {
        word: 'קונים',
        vocalization: 'קונים',
        translation: 'покупают',
        transcription: 'конИм',
      },
      {
        word: 'קונות',
        vocalization: 'קונות',
        translation: 'покупают',
        transcription: 'конОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'רואה',
    vocalization: 'רואה',
    translation: '(он) видит',
    enTranslation: 'see',
    transcription: 'роЭ',
    infinitive: {
      word: 'לראות',
      vocalization: 'לראות',
      translation: 'Видеть',
      transcription: 'лиръОт',
    },
    present: [
      {
        word: 'רואה',
        vocalization: 'רואה',
        translation: 'видит',
        transcription: 'роЭ',
      },
      {
        word: 'רואה',
        vocalization: 'רואה',
        translation: 'видит',
        transcription: 'роА',
      },
      {
        word: 'רואים',
        vocalization: 'רואים',
        translation: 'видят',
        transcription: 'роИм',
      },
      {
        word: 'רואות',
        vocalization: 'רואות',
        translation: 'видят',
        transcription: 'роОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'עושה',
    vocalization: 'עושה',
    translation: '(он) делает',
    enTranslation: 'do',
    transcription: 'осЭ',
    infinitive: {
      word: 'לעשות',
      vocalization: 'לעשות',
      translation: 'Делать',
      transcription: 'лаасОт',
    },
    present: [
      {
        word: 'עושה',
        vocalization: 'עושה',
        translation: 'делает',
        transcription: 'осЭ',
      },
      {
        word: 'עושה',
        vocalization: 'עושה',
        translation: 'делает',
        transcription: 'осА',
      },
      {
        word: 'עושים',
        vocalization: 'עושים',
        translation: 'делают',
        transcription: 'осИм',
      },
      {
        word: 'עושות',
        vocalization: 'עושות',
        translation: 'делают',
        transcription: 'осОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'אוכל',
    vocalization: 'אוכל',
    translation: '(он) ест',
    enTranslation: 'eat',
    transcription: 'охЭль',
    infinitive: {
      word: 'לאכול',
      vocalization: 'לאכול',
      translation: 'Есть',
      transcription: 'лээхОль',
    },
    present: [
      {
        word: 'אוכל',
        vocalization: 'אוכל',
        translation: 'ест',
        transcription: 'охЭль',
      },
      {
        word: 'אוכלת',
        vocalization: 'אוכלת',
        translation: 'ест',
        transcription: 'охЭлет',
      },
      {
        word: 'אוכלים',
        vocalization: 'אוכלים',
        translation: 'едят',
        transcription: 'охлИм',
      },
      {
        word: 'אוכלות',
        vocalization: 'אוכלות',
        translation: 'едят',
        transcription: 'охлОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'שותה',
    vocalization: 'שותה',
    translation: '(он) пьет',
    enTranslation: 'drink',
    transcription: 'шотЭ',
    infinitive: {
      word: 'לשתות',
      vocalization: 'לשתות',
      translation: 'Пить',
      transcription: 'лиштОт',
    },
    present: [
      {
        word: 'שותה',
        vocalization: 'שותה',
        translation: 'пьет',
        transcription: 'шотЭ',
      },
      {
        word: 'שותה',
        vocalization: 'שותה',
        translation: 'пьет',
        transcription: 'шотА',
      },
      {
        word: 'שותים',
        vocalization: 'שותים',
        translation: 'пьют',
        transcription: 'шотИм',
      },
      {
        word: 'שותות',
        vocalization: 'שותות',
        translation: 'пьют',
        transcription: 'шотОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'יושב',
    vocalization: 'יושב',
    translation: '(он) сидит',
    enTranslation: 'sit',
    transcription: 'йошЕв',
    infinitive: {
      word: 'לשבת',
      vocalization: 'לשבת',
      translation: 'Сидеть',
      transcription: 'лашЭвед',
    },
    present: [
      {
        word: 'יושב',
        vocalization: 'יושב',
        translation: 'сидит',
        transcription: 'йошЕв',
      },
      {
        word: 'יושבת',
        vocalization: 'יושבת',
        translation: 'сидит',
        transcription: 'йошЕвед',
      },
      {
        word: 'יושבים',
        vocalization: 'יושבים',
        translation: 'сидят',
        transcription: 'йошвИм',
      },
      {
        word: 'יושבות',
        vocalization: 'יושבות',
        translation: 'сидят',
        transcription: 'йошвОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'חי',
    vocalization: 'חי',
    translation: '(он) живет',
    enTranslation: 'alive',
    transcription: 'хАй',
    infinitive: {
      word: 'לחיות',
      vocalization: 'לחיות',
      translation: 'Жить',
      transcription: 'лихйОт',
    },
    present: [
      {
        word: 'חי',
        vocalization: 'חי',
        translation: 'живет',
        transcription: 'хАй',
      },
      {
        word: 'חיה',
        vocalization: 'חיה',
        translation: 'живет',
        transcription: 'хаЯ',
      },
      {
        word: 'חיים',
        vocalization: 'חיים',
        translation: 'живут',
        transcription: 'хайИм',
      },
      {
        word: 'חיות',
        vocalization: 'חיות',
        translation: 'живут',
        transcription: 'хайОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'משחק',
    vocalization: 'משחק',
    translation: '(он) играет',
    enTranslation: 'play',
    transcription: 'месахЕк',
    infinitive: {
      word: 'לשחק',
      vocalization: 'לשחק',
      translation: 'Играть',
      transcription: 'лесахЕк',
    },
    present: [
      {
        word: 'משחק',
        vocalization: 'משחק',
        translation: 'играет',
        transcription: 'месахЕк',
      },
      {
        word: 'משחקת',
        vocalization: 'משחקת',
        translation: 'играет',
        transcription: 'месахЕкет',
      },
      {
        word: 'משחקים',
        vocalization: 'משחקים',
        translation: 'играют',
        transcription: 'месахакИм',
      },
      {
        word: 'משחקות',
        vocalization: 'משחקות',
        translation: 'играют',
        transcription: 'месахакОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'מרגיש',
    vocalization: 'מרגיש',
    translation: '(он) чувствует',
    enTranslation: 'feel',
    transcription: 'маргИш',
    infinitive: {
      word: 'להרגיש',
      vocalization: 'להרגיש',
      translation: 'Чувствовать',
      transcription: 'леhаргИш',
    },
    present: [
      {
        word: 'מרגיש',
        vocalization: 'מרגיש',
        translation: 'чувствует',
        transcription: 'маргИш',
      },
      {
        word: 'מרגישה',
        vocalization: 'מרגישה',
        translation: 'чувствует',
        transcription: 'маргишА',
      },
      {
        word: 'מרגישים',
        vocalization: 'מרגישים',
        translation: 'чувствуют',
        transcription: 'маргишИм',
      },
      {
        word: 'מרגישות',
        vocalization: 'מרגישות',
        translation: 'чувствуют',
        transcription: 'маргишОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
  {
    word: 'רוצה',
    vocalization: 'רוצה',
    translation: '(он) хочет',
    enTranslation: 'want',
    transcription: 'роцЭ',
    infinitive: {
      word: 'לרצות',
      vocalization: 'לרצות',
      translation: 'Хотеть',
      transcription: 'лирцОт',
    },
    present: [
      {
        word: 'רוצה',
        vocalization: 'רוצה',
        translation: 'хочет',
        transcription: 'роцЭ',
      },
      {
        word: 'רוצה',
        vocalization: 'רוצה',
        translation: 'хочет',
        transcription: 'роцА',
      },
      {
        word: 'רוצים',
        vocalization: 'רוצים',
        translation: 'хотят',
        transcription: 'роцИм',
      },
      {
        word: 'רוצות',
        vocalization: 'רוצות',
        translation: 'хотят',
        transcription: 'роцОт',
      },
    ],
    ruTopic: ['Глаголы'],
    enTopic: ['Verbs'],
  },
];

export const alefbet: TLetter[] = [
  {
    letter: 'ק',
  },
  {
    letter: 'ר',
  },
  {
    letter: 'א',
  },
  {
    letter: 'א',
  },
  {
    letter: 'ט',
  },
  {
    letter: 'ו',
  },
  {
    letter: 'ן',
  },
  {
    letter: 'ם',
  },
  {
    letter: 'פ',
  },
  {
    letter: 'ש',
  },
  {
    letter: 'ד',
  },
  {
    letter: 'ג',
  },
  {
    letter: 'כ',
  },
  {
    letter: 'ע',
  },
  {
    letter: 'י',
  },
  {
    letter: 'ח',
  },
  {
    letter: 'ל',
  },
  {
    letter: 'ך',
  },
  {
    letter: 'ף',
  },
  {
    letter: 'ז',
  },
  {
    letter: 'ס',
  },
  {
    letter: 'ב',
  },
  {
    letter: 'ה',
  },
  {
    letter: 'נ',
  },
  {
    letter: 'מ',
  },
  {
    letter: 'י',
  },
  {
    letter: 'צ',
  },
  {
    letter: 'ת',
  },
  {
    letter: 'ץ',
  },
  {
    letter: 'ץ',
  },
];
